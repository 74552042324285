import React, { Component, } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { pageName, pageTitle, page } from '../../dataStore/actions';
import ReactResizeDetector from 'react-resize-detector';
import srcSet from '../../srcSet';
import FormBuilder from '../../components/FormBuilder';

var s = srcSet.s

class HomePage extends Component {

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    pageYOffset:0,
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    await this.props.dispatch(pageName('Home'))
    await this.props.dispatch(pageTitle(`${this.props.pageName} | KBM`))
    await this.props.dispatch(page('home'))
  }

  onResize = () => {
    this.setState({
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  render() {
    const {} = this.state
    const {} = this.props

    return (
      <div className="container p-5">
        <div className="bg-light rounded-3 p-5 mb-4">
          <h2>Home Page</h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect (mapStateToProps)(HomePage);
