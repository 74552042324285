
export const page = page => ({
    type: 'page',
    page
})

export const pageName = pageName => ({
    type: 'pageName',
    pageName
})

export const pageTitle = pageTitle => ({
    type: 'pageTitle',
    pageTitle
})

export const toggleSidebar = toggleSidebar => ({
    type: 'toggleSidebar',
    toggleSidebar
})

export const objArr = objArr => ({
    type: 'objArr',
    objArr
})

export const connecting = connecting => ({
    type: 'connecting',
    connecting
})

export const toggleConnectSection = toggleConnectSection => ({
    type: 'toggleConnectSection',
    toggleConnectSection
})

export const toggleConnectField = toggleConnectField => ({
    type: 'toggleConnectField',
    toggleConnectField
})

