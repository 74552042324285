import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BsThreeDots, } from 'react-icons/bs';

class ElementBox extends Component {

  state = {}

  componentDidMount() {}

  render() {
    const { type, label, objN } = this.props
    const n = objN[type];

    const boxClass = `elm-static-box grabbable ${type === 'section' ? 'new-selection' : 'd-flex justify-content-between'} ${type === 'multiLine' ? 'multi-line' : ''}`;
    const inputClass = `${type === 'section' ? 'select-static-input' : 'elm-static-input'}`;
    const pub = ['singleLine', 'multiLine', 'number', 'email', 'longInteger', 'date',
    'datetime', 'percent', 'currency', 'phone', 'url', 'pickList', 'multiSelect',
    'imageUpload', 'fileUpload', 'lookup']

    return (
      <div key={n} id={type + n} className={boxClass}>
        <div className={inputClass}>{`${label} ${objN[type]}`}</div>
        {type === 'section' && <div className='select-section-col'></div>}
        {type === 'checkbox' && <div style={{width:'100%', padding:'0px 10px'}}><div className='checkbox-label'></div></div>}
        {pub.includes(type) && <div className='elm-label'>{label}</div>}
        {type !== 'section' && <BsThreeDots className='elm-more' />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect (mapStateToProps)(ElementBox);

