import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from './pages/Public/HomePage';
import FormBuilderPage from './pages/Public/FormBuilderPage';
import FormDataPage from './pages/Public/FormDataPage';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/form-builder' component={FormBuilderPage} />
        <Route exact path='/form-data' component={FormDataPage} />
      </Switch>
    );
  }
}

export default Routes;
