import { combineReducers } from 'redux';

import { page } from './other/page';
import { pageName } from './other/pageName';
import { pageTitle } from './other/pageTitle';
import { toggleSidebar } from './other/toggleSidebar';
import { objArr } from './other/objArr';
import { connecting } from './other/connecting';
import { toggleConnectSection } from './other/toggleConnectSection';
import { toggleConnectField } from './other/toggleConnectField';

export default combineReducers({
    page, pageName, pageTitle, toggleSidebar, objArr, connecting, 
    toggleConnectSection, toggleConnectField, 
})