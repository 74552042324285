import React from 'react'; 
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './dataStore/reducers';
import ReactDOM from 'react-dom';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './public/css/loader.scss';
import './public/css/project.css';
import './public/css/style.css';
import './public/css/popup.css';
import { loadState, saveState } from './localStorage';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
const persistedState = loadState();
const store = createStore(reducers, persistedState)

store.subscribe(() => {
    saveState(store.getState());
})

ReactDOM.render(
    <Provider store = {store}>
        <App/>
    </Provider> , document.getElementById('root')
);

registerServiceWorker();

const storeX = store.getState();

export default storeX;

