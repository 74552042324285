import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { connecting, toggleConnectSection, toggleConnectField } from '../dataStore/actions';
import ElementBox from './ElementBox';
import { BsThreeDots } from 'react-icons/bs';
import { FiPlusSquare, FiMinusSquare } from 'react-icons/fi';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiOutlineSetting } from 'react-icons/ai';
import { MdDragIndicator } from 'react-icons/md';
import srcSet from '../srcSet';
import { Form, Button, Popover, Overlay, Modal, InputGroup, Card, DropdownButton,
  ButtonGroup, Dropdown, } from 'react-bootstrap';
import { previewArrMap, connectSection, connectField, removeElm,
  setMultiSelect,  } from '../modules/FormHelper';

const { serverURL, objects, formBuilderBasicState } = srcSet

class FormBuilder extends Component {
  //Deep Copy
  state = JSON.parse(JSON.stringify(formBuilderBasicState))

  componentDidMount = async () => {
    this.setProps()
    this.getElmPos()
    this.formBoxScroll()
    this.createStatic()
  }

  setProps = () => {
    this.props.dispatch(connecting(false))
    this.props.dispatch(toggleConnectSection(false))
  }

  createStatic = () => {
    var staticObj = {}
    for(const [key, value] of Object.entries(objects)) {
      staticObj[`${key}`] = (
        <div id={key}
          className={`center static ${key==='section' && 'w100p'} disable-select`}
          onMouseDown={() => this.createElm(`${key}`)}
        >
          {value}
        </div>
      )
    }
    this.setState({ staticObj })
  }

  renameKey = async (target) => {
    const keyArr = target.key.split('-')
    // console.log(1000,  target.key, keyArr)
    if(keyArr.length>1 && keyArr[1]?.length===13) {
      // console.log(true)
      target.key = keyArr[0]
    }
    const date = new Date();
    const dateN = date.getTime();
    const arr = [target]
    for(let i=0; i<arr.length; i++) {
      arr[i].key = arr[i].key + '-' + dateN
      const arr2 = arr[i].components
      if(arr2) {
        for(let i2=0; i2<arr2.length; i2++) {
          arr2[i2].key = arr2[i2].key + '-' + dateN
          const arr3 = arr2[i2].components
          for(let i3=0; i3<arr3.length; i3++) {
            arr3[i3].key = arr3[i3].key + '-' + dateN
          }
        }
      }
    }
    return arr
  }

  getObjN = async () => {
    const objN = { section: 0, singleLine: 0, multiLine: 0, number: 0, email: 0, checkbox: 0,
      longInteger: 0, date: 0, datetime: 0, percent: 0, currency:0, phone:0, url:0, pickList:0,
      multiSelect: 0, imageUpload: 0, fileUpload: 0, lookup: 0, };
    const arr = this.state.objectArr
    // console.log(arr)
    for(let i=0; i<arr.length; i++) {
      objN.section++
      const arr2 = arr[i].components
      for(let i2=0; i2<arr2.length; i2++) {
        // console.log(arr2)
        const arr3 = arr2[i2].components
        for(let i3=0; i3<arr3.length; i3++) {
          const type = arr3[i3].type;
          objN[type]++;
        }
      }
    }
    this.setState({ objN })
  }

  formBoxScroll = () => {
    const elmntX = document.getElementById('formBox')
    if(elmntX) {
      elmntX.addEventListener('scroll', (e) => {
        // this.setState({preXp: this.state.formBoxScrollPos})
        this.setState({formBoxScrollPos: elmntX.scrollTop})
        // console.log(this.state.formBoxScrollPos)
      })
    }
  }

  optionBoxScroll = async () => {
    const elmntX = document.getElementById('optionBox')
    // console.log(elmntX)
    if(elmntX) {
      elmntX.addEventListener('scroll', (e) => {
        // this.setState({preXp: this.state.formBoxScrollPos})
        this.setState({optionBoxScrollPos: elmntX.scrollTop})
        // console.log(this.state.optionBoxScrollPos)
      })
    }
  }

  addOption = (objectArr, i, ic, icc, ix) => {
    const target = objectArr[i].components[ic].components[icc].options
    const date = new Date();
    const dateN = date.getTime();
    const newObj = {
      "key": `option-${dateN}`,
      "order": 0,
      "type": "option",
      "name": "Option",
      "label": `Option ${target.length + 1}`
    }
    target.splice(ix+1, 0, newObj)
    this.optionArrMap(objectArr, i, ic, icc)
  }

  deleteOption = (objectArr, i, ic, icc, ix) => {
    const target = objectArr[i].components[ic].components[icc].options
    const { elmIdArr } = this.state
    if(target.length>1) {
      delete elmIdArr[`${target[ix].key}`]
      if(target[ix].defaultValue) {
        this.setState({ optionDefaultValue: false})
      }
      target.splice(ix, 1)
      this.optionArrMap(objectArr, i, ic, icc)
    }
  }

  scrollBy = (y) => {
    const speed = 400
    const space = 20
    const { elmIdArr } = this.state
    var formTop = elmIdArr.formBox.top
    var formBottom = elmIdArr.formBox.bottom
    const elmnt = document.getElementById('formBox')
    // console.log(y , formTop)

    if(y < formTop + space) {
      elmnt.scrollBy({
        top: -speed,
        behavior: "smooth",
      });
    } else if(y > formBottom - space) {
      elmnt.scrollBy({
        top: speed,
        behavior: "smooth",
      });
    }
  }

  clearKey = (key) => {
    return key.replace(/[0-9]/g, '').replaceAll('-', '')
  }

  makeObjIdArr = (arr) => {
    var elmIdArr = this.state.elmIdArr
    for(let i=0; i<arr.length; i++) {
      // console.log(arr[i])
      if(arr[i]) {
        var key = arr[i];
        elmIdArr[key] = { type: this.clearKey(key) }; //top: 0, left: 0, bottom: 0, right: 0 
      }
    }
  }

  optionArrMap = async (objectArr, i, ic, icc, movement) => {
    const optionArr = i>=0 ? objectArr[i].components[ic].components[icc].options : []
    var itemArr = [], labelNull = 0
    var options = await optionArr.map(
      (item, ix) => (
        labelNull = item.label==='' ? labelNull+1 : labelNull,
        itemArr.push(item.key),
        <div key={ix} id={item.key} className='d-flex option-parent grabbable'
          style={{width:'100%', marginBottom:'10px', position:'relative', alignItems:'center'}}
        >
          <input type="text" className={`option-input form-control ${item.label==='' && 'nullInput'}`}
            defaultValue={item.label}
            value={item.label}
            name={item.label}
            id={`${item.key}-input`}
            placeholder={item.label==='' ? 'Enter a label . . .' : ''}
            onChange={(e) => this.changeOptionLabel(e, objectArr, i, ic, icc, ix)}
          />
          <MdDragIndicator id='drager' onMouseDown={(e) => this.toggleOption(e, objectArr, i, ic, icc, ix, item.key)}
            style={{height:'30px', width:'25px', padding:'0px 5px', position:'absolute', zIndex:'1000', top:0, left:0, cursor:'move'}}
          />
          <div className='d-flex option-child' style={{ visibility: movement ? 'hidden' : '' }}>
            <AiOutlinePlusCircle className='hover optionBtn blue-hover' style={{}} onClick={()=>this.addOption(objectArr, i, ic, icc, ix)}/>
            <AiOutlineMinusCircle className='hover optionBtn red-hover' style={{}} onClick={()=>this.deleteOption(objectArr, i,  ic, icc, ix)}/>
          </div>
        </div>
      )
    )

    await this.clearOptions()
    this.makeObjIdArr(itemArr)
    this.setState({ options, optionArr, labelNull: labelNull > 0 ? true : false })
    this.getElmPos()
  }

  clearOptions = async () => {
    const { elmIdArr } = this.state
    for(const [key, value] of Object.entries(elmIdArr)) {
      if(value.type==='option') {
        delete elmIdArr[`${key}`]
      }
    }
  }

  objectArrMap = async () => {
    const { objectArr, moreTarget, sectionMoreTarget, elmIdArr } = this.state
    const checkbox = <div className='checkbox-label' style={{marginTop:'5px'}}></div>
    var itemArr = [], sectionFieldsQty, colN, labelNull = 0
    // console.log(121212, objectArr)
    var objects = await objectArr.map(
      (item, i) => (
        labelNull = item.label==='' ? labelNull+1 : labelNull,
        itemArr.push(item.key),
        sectionFieldsQty = this.countSectionFields(item),
        colN = item.components.length,
        // console.log(colN),
        <div key={i} id={item.key}
          className='elm-section section-parent'
        >
          <div id='sectionHeader' className='section-header'
            onMouseDown={(e) => this.toggleSection(e, item.key)}
          >
            <input type="text" className={`elm-section-input section-child form-control ${item.label==='' && 'nullInput'}`}
              defaultValue={item.label}
              value={item.label}
              name={item.label}
              id={`input-${i}`}
              placeholder={item.label==='' ? 'Enter a label . . .' : ''}
              // onClick={() => this.focus(itemL3.label, itemL3.label + 'input')}
              onChange={(e) => this.changeLabel(e, 'section', objectArr, i, null, null)}
            />
            <div>
              <AiOutlineSetting id='popover' className='section-settings-icon' onClick={(e) => this.toggleSectionMore(e, i)}/>
              <Overlay
                show={item.more}
                target={sectionMoreTarget}
                placement="bottom"
                // container={ref}
                containerPadding={10}
              >
                <Popover id="popover-contained">
                  <Popover.Body style={{padding:'5px 0px'}}>
                    <div className="elm-drop-title">
                      Section Layout
                    </div>
                    <div id='dropItem' className="elm-drop-item disable-select"
                      onClick={() => this.changeColumn(i, 1)}>
                      <span className='checkmark' style={{visibility: colN===1 ? '' : 'hidden'}}>✓</span>
                      <span>Single Column</span>
                    </div>
                    <div id='dropItem' className="elm-drop-item disable-select"
                      onClick={() => this.changeColumn(i, 2)}>
                      <span className='checkmark' style={{visibility: colN===2 ? '' : 'hidden'}}>✓</span>
                      <span>Double Column</span>
                    </div>
                    <div id='dropItem' className="elm-drop-item"
                      onClick={() => this.toggleModalConnectSection(i)}>
                      Connect Section
                    </div>
                    <hr className='elm-drop-line'/>
                    <div id='dropItem' className="elm-drop-item font-red"
                      onClick={() => this.toggleDeleteSection(i)}
                    >
                      Remove Section
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </div>
          <div className='elm-section-box'>
            {item.components.map(
              (itemL2, ic) => (
                // console.log(itemL2),
                itemArr.push(itemL2.key),
                <div key={ic} id={itemL2.key} className='elm-section-col' style={{backgroundColor: sectionFieldsQty===0 ? '' : 'transparent'}}>
                  {itemL2.components.map(
                    (itemL3, icc) => (
                      labelNull = itemL3.label==='' ? labelNull+1 : labelNull,
                      itemArr.push(itemL3.key),
                      <div key={icc} id={itemL3.key}
                        className={`elm-box ${itemL3.type==='multiLine' ? 'multi-line' : ''} input-parent justify-content-between disable-select`}
                        style={{borderLeft: itemL3.required ? '2px solid red' : ''}}
                        onMouseDown={(e) => this.toggleElm(e, itemL3.key, itemL3.type)}
                      >
                        <input type="text" className={`elm-input input-child form-control ${itemL3.label==='' && 'nullInput'}`}
                          defaultValue={itemL3.label}
                          value={itemL3.label}
                          name={itemL3.label}
                          id={`input-${i}-${ic}-${icc}`}
                          placeholder={itemL3.label==='' ? 'Enter a label . . .' : ''}
                          onChange={(e) => this.changeLabel(e, 'element', objectArr, i, ic, icc)}
                        />
                        <div className='elm-label'>{itemL3.type!=='checkbox' ? itemL3.name : checkbox}</div>
                        <div>
                          <BsThreeDots id='popover' className='elm-more' onClick={(e) => this.toggleMore(e, i, ic, icc)}/>
                          <Overlay
                            show={itemL3.more}
                            target={moreTarget}
                            placement="bottom"
                            // container={ref}
                            containerPadding={10}
                          >
                            <Popover id="popover-contained">
                              <Popover.Body style={{padding:'5px 0px'}}>
                                <div id='dropItem' className="elm-drop-item"
                                  onClick={() => this.requiredObj(i, ic, icc)}>
                                  <span id='dropItem' className='checkmark' style={{display: itemL3.required ? '' : 'none'}}>✓</span>
                                  <span id='dropItem'>Required</span>
                                  <span className='font-red'>*</span>
                                </div>
                                <div id='dropItem' className="elm-drop-item"
                                  onClick={() => console.log(2)}>
                                  Set Permission
                                </div>
                                <div id='dropItem' className="elm-drop-item"
                                  onClick={() => console.log(3)}>
                                  Edit Permission
                                </div>
                                <div id='dropItem' className="elm-drop-item"
                                  onClick={() => this.toggleModalConnectField(i, ic, icc)}>
                                  Connect Field
                                </div>
                                { ['pickList', 'multiSelect', 'lookup'].includes(itemL3.type) &&
                                  <div id='dropItem' className="elm-drop-item"
                                    onClick={() => this.toggleProperties(itemL3.type, i, ic, icc)}>
                                    Edit Properties
                                  </div>
                                }
                                <hr className='elm-drop-line'/>
                                <div id='dropItem' className="elm-drop-item font-red"
                                  onClick={() => this.toggleDeleteObj(i, ic, icc)}>
                                  Remove Field
                                </div>
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )
    )

    this.makeObjIdArr(itemArr)
    this.setState({ objects, labelNull: labelNull > 0 ? true : false })
    this.getElmPos()
  }

  fieldConnectSelect = async (target, forms, i, s) => {
    var fCIndex = this.state.fCIndex
    target = await this.renameKey(target)
    this.setState({
      targetX: target[0],
      formsX: forms,
    })
    fCIndex[i] = s
  }

  sectionConnectSelect = async (e, target, forms, i) => {
    var formConnectArr = this.state.formConnectArr
    const checked = e.target.checked
    const form = forms[i].form
    const id = forms[i]._id
    
    if(checked) {
      target = await this.renameKey(target)
      form.push(target[0])
      formConnectArr.push(forms[i])
    } else {
      target.key = target.key.split('-')[0]
      form.pop()
      for(let x=0; x<formConnectArr.length; x++) {
        if(formConnectArr[x].id === id) formConnectArr.splice(x, 1)
      }
    }
  }

  toggleSection = async (e, id) => {
    // console.log(e.target.id)
    if(!['sectionHeader'].includes(e.target.id)) {
      // console.log('other')
    } else {
      const { labelNull, objectArr, elmIdArr, formBoxScrollPos } = this.state
      if(labelNull) {
        this.onLabelNull(true)
      } else {
        var x, y, ei, ti, arr=[], elmX, direction, preY, pressing = true
        var xp = formBoxScrollPos
        const elmnt = document.getElementById(id)
        const wx = elmnt.offsetWidth
        const hx = elmnt.offsetHeight
        const tx = elmnt.offsetTop
        const lx = elmnt.offsetLeft
        const bx = tx + hx
  
        var tdif = e.clientY - tx
        var ldif = e.clientX - lx
        elmnt.style.top = e.clientY - tdif - xp + "px";
        elmnt.style.left = e.clientX - ldif + "px";
  
        var objNull = document.createElement('div');
        objNull.id = 'objNull';
        objNull.className = "section-placement";
  
        const preXp = this.state.formBoxScrollPos
        dragMouseDown()
        function dragMouseDown(e) {
  
          for(var x=0; x<objectArr.length; x++) {
            const section = document.getElementById(objectArr[x].key)
            section.style.height = '150px'
            section.style.overflow = 'hidden'
          }
  
          for(const [key, value] of Object.entries(elmIdArr)) {
            const elmnt = document.getElementById(`${key}`)
            // console.log(key, elmnt)
            if(value.type==='section') {
              const type = value.type
              const top = elmnt.offsetTop
              const left = elmnt.offsetLeft
              const width = elmnt.offsetWidth
              const height = elmnt.offsetHeight
              const bottom = top + height
              const right = left + width
              
              elmIdArr[`${key}`] = { type, top, left, bottom, right, width, height }
            }
          }

          elmnt.style.position = 'absolute';
          elmnt.style.width = `${wx}px`;
          elmnt.parentNode.insertBefore(objNull, elmnt);
  
        }
  
        document.addEventListener('mousemove', (e) => {
          if (pressing) {
            x = e.clientX
            y = e.clientY
            var xp = this.state.formBoxScrollPos
            elmnt.style.left = x - ldif + "px";
            elmnt.style.top = y - tdif - preXp + "px";
  
            this.scrollBy(y)
            const newXp = this.state.formBoxScrollPos
  
            var index = 0
            for(const [key, value] of Object.entries(elmIdArr)) {
              if(value.type==='section') {
                const left = elmIdArr[`${key}`].left
                const right = elmIdArr[`${key}`].right
                const top = elmIdArr[`${key}`].top
                const bottom = elmIdArr[`${key}`].bottom
  
                const newTop = top - newXp
                const newBottom = bottom - newXp
                const targetIndex = x > left && x < right && y > newTop && y < newBottom;
  
                if(targetIndex) {
                  index++
                  elmX = key
                }
  
              }
            }
  
            arr = objectArr
  
            if(y < preY) {
              direction='up'
            } else if(y > preY) {
              direction='down'
            }
  
            for(let i=0; i<arr.length; i++) {
              const idx = arr[i].key
              const target = document.getElementById(idx)
              const topx = elmIdArr[`${idx}`].top
              const bottomx = elmIdArr[`${idx}`].bottom
  
              const newTop = topx - newXp
              const newBottom = bottomx - newXp
              const targetIndex = y > newTop && y < newBottom;
              if(targetIndex) {
  
                ei = [...elmnt.parentNode.children].indexOf(elmnt)
                ti = [...target.parentNode.children].indexOf(target)
  
                removeElm('objNull')
                // console.log(ei, ti)
                if(ti>ei) {
                  //Append After:
                  target.parentNode.insertBefore(objNull, target.nextSibling);
                } else {
                  //Append Before:
                  target.parentNode.insertBefore(objNull, target);
                }
  
              }
            }
  
          }
          preY = y
        }, false);
  
        elmnt.addEventListener('mouseup', async (e) => {
          if(pressing){
            // console.log(this.state.formBoxScrollPos)
  
            removeElm('objNull')
            if(ei!==ti) {
              const components = objectArr
              if(ei<ti) {
                const eElm = components[ei]
                components.splice(ei, 1)
                components.splice(ti, 0, eElm)
              } else {
                ei--
                ti--
                // console.log(ei, ti)
                const eElm = components[ei]
                components.splice(ti, 0, eElm)
                components.splice(ei+1, 1)
                // console.log(components)
              }
            }
  
            for(var x=0; x<objectArr.length; x++) {
              const section = document.getElementById(objectArr[x].key)
              section.style.height = ''
              section.style.overflow = ''
            }
    
            elmnt.style.position = '';
            elmnt.style.width = `100%`;
            // elmnt.style.opacity = '1';
            this.objectArrMap()
  
            pressing = false;
          }
        }, false);
  
      }
    }

  }

  toggleOption = async (e, objectArr, i, ic, icc, ix, id) => {
    const { labelNull, elmIdArr, optionBoxScrollPos } = this.state
    if(labelNull) {
      this.onLabelNull(true)
    } else {
      this.optionArrMap(objectArr, i, ic, icc, ix, true)

      var x, y, ei, ti, arr=[], elmX, direction, preY, pressing = true
      var xp = optionBoxScrollPos
      const elmnt = document.getElementById(id)
      const elmntInput = document.getElementById(`${id}-input`)
      const wn = elmntInput.offsetWidth
      const wx = elmnt.offsetWidth
      const hx = elmnt.offsetHeight
      const tx = elmnt.offsetTop
      const lx = elmnt.offsetLeft
      const bx = tx + hx

      var tdif = e.clientY - tx
      var ldif = e.clientX - lx
      elmnt.style.top = e.clientY - tdif - xp + "px";
      elmnt.style.left = e.clientX - ldif + "px";

      var objNull = document.createElement('div');
      objNull.id = 'objNull';
      objNull.style.width = `${wn}px`;
      objNull.className = "option-placement";

      const preXp = this.state.optionBoxScrollPos
      dragMouseDown()
      function dragMouseDown(e) {

        for(const [key, value] of Object.entries(elmIdArr)) {
          const elmnt = document.getElementById(`${key}`)
          // console.log(key, elmnt)
          if(value.type==='option') {
            const type = value.type
            const top = elmnt.offsetTop
            const left = elmnt.offsetLeft
            const width = elmnt.offsetWidth
            const height = elmnt.offsetHeight
            const bottom = top + height
            const right = left + width

            elmIdArr[`${key}`] = { type, top, left, bottom, right, width, height }
          }
        }

        elmnt.style.position = 'absolute';
        elmnt.style.width = `${wx}px`;
        elmnt.style.zIndex = 1001;
        elmnt.parentNode.insertBefore(objNull, elmnt);

      }
      this.getElmPos()
      document.addEventListener('mousemove', (e) => {
        if (pressing) {
          x = e.clientX
          y = e.clientY

          var xp = this.state.optionBoxScrollPos
          elmnt.style.left = x - ldif + "px";
          elmnt.style.top = y - tdif - preXp + "px";
          // console.log(elmnt.style.top, elmnt.style.left)
          // console.log(elmIdArr)
          this.scrollBy(y)
          const newXp = this.state.optionBoxScrollPos

          var index = 0
          for(const [key, value] of Object.entries(elmIdArr)) {
            if(value.type==='option') {
              const left = elmIdArr[`${key}`].left
              const right = elmIdArr[`${key}`].right
              const top = elmIdArr[`${key}`].top
              const bottom = elmIdArr[`${key}`].bottom
              // console.log(key, top)
              const newTop = top - newXp
              const newBottom = bottom - newXp
              const targetIndex = x > left && x < right && y > newTop && y < newBottom;

              if(targetIndex) {
                // console.log(1)
                index++
                elmX = key
              }

            }
          }

          arr = objectArr[i].components[ic].components[icc].options

          if(y < preY) {
            direction='up'
          } else if(y > preY) {
            direction='down'
          }

          for(let i=0; i<arr.length; i++) {
            const idx = arr[i].key
            const target = document.getElementById(idx)
            const topx = elmIdArr[`${idx}`].top
            const bottomx = elmIdArr[`${idx}`].bottom
            
            const newTop = topx - newXp + 80
            const newBottom = bottomx - newXp + 80

            const targetIndex = y > newTop && y < newBottom;
            if(targetIndex) {
              ei = [...elmnt.parentNode.children].indexOf(elmnt)
              ti = [...target.parentNode.children].indexOf(target)

              removeElm('objNull')
              // console.log('ei: ', ei, 'ti: ', ti)

              if(ti>ei) {
                //Append After:
                target.parentNode.insertBefore(objNull, target.nextSibling);
              } else {
                //Append Before:
                target.parentNode.insertBefore(objNull, target);
              }

            }
          }

        }
        preY = y
      }, false);

      elmnt.addEventListener('mouseup', async (e) => {
        if(pressing){
          removeElm('objNull')

          if(ei!==ti) {
            // console.log('ei: ', ei, 'ti: ', ti)
            const components = objectArr[i].components[ic].components[icc].options
            // console.log(components)
            if(ei<ti) {
              const eElm = components[ei]
              components.splice(ei, 1)
              components.splice(ti, 0, eElm)
            } else {
              ei--
              ti--
              // console.log(ei, ti)
              const eElm = components[ei]
              components.splice(ti, 0, eElm)
              components.splice(ei+1, 1)
              // console.log(components)
            }
          }

          // const objNull = document.getElementById('objNull')
          // const objNt = objNull.offsetTop

          elmnt.style.position = '';
          elmnt.style.width = `${wx}px`;
          // elmnt.style.top = `${objNt}px`;
          elmnt.style.left = objNull.style.left;
          // elmnt.style.opacity = '1';
          // console.log(elmnt)
          // console.log(optionArr)
          
          // console.log(1999)
          await this.optionArrMap([])

          this.optionArrMap(objectArr, i, ic, icc)
          // this.getElmPos()

          pressing = false;
        }
      }, false);

    }
  }

  getElmData = (type) => {
    const { objN } = this.state
    const elm = <ElementBox key={type} type={type} label={objects[type]} objN={objN} />;
    const n = objN[type];
    return { elm, n }
  }

  getElmPos = async () => {
    const { elmIdArr } = this.state
    // console.log(111,elmIdArr )
    for(const [key, value] of Object.entries(elmIdArr)) {
      // console.log(111, key, value)
      const elmnt = document.getElementById(`${key}`)
      if(elmnt) {
        const type = value.type
        const top = elmnt.offsetTop
        const left = elmnt.offsetLeft
        const width = elmnt.offsetWidth
        const height = elmnt.offsetHeight
        const bottom = top + height
        const right = left + width
        
        elmIdArr[`${key}`] = { type, top, left, bottom, right, width, height }
      }
    }
    this.setState({elmIdArr})
  }

  toggleFormCnt = (target, forms, i) => {
    // console.log(i)
    var fCIndex = this.state.fCIndex
    Reflect.deleteProperty(fCIndex, i);
    // this.mapSection(forms[i].form)
    forms[i].toggleForm = !forms[i].toggleForm
    this.mapFieldConnect(target, forms, i)
  }

  toggleModalPickList = () => {
    this.setState({
      togglePickList: !this.state.togglePickList,
    })
  }

  toggleModalMultiSelect = () => {
    this.setState({
      toggleMultiSelect: !this.state.toggleMultiSelect,
    })
  }

  toggleModalLookup = () => {
    this.setState({
      toggleLookup: !this.state.toggleLookup,
    })
  }

  onOptionDone = (x) => {
    if(this.state.labelNull) {
      this.setState({ alertLabelNull: true });
    } else {
      this.setState({ [`${x}`]: !this.state[x] })
    }
  }

  toggleDeleteForm = (item) => {
    if(item) {
      item.label = item.formTitle
      this.setState({
        targetX: item,
      }) 
    }
    this.setState({
      toggleDeleteForm: !this.state.toggleDeleteForm,
    })
  }

  toggleDeleteSection = (i) => {
    if(i>=0) {
      const objectArr = this.state.objectArr
      const target = objectArr[i]
      target.more = undefined
      this.objectArrMap()
      this.setState({
        targetX: target,
        ix: i,
      }) 
    }
    this.setState({
      toggleDeleteSection: !this.state.toggleDeleteSection,
    })
  }

  toggleDeleteObj = (i, ic, icc) => {
    if(i>=0) {
      const objectArr = this.state.objectArr
      const target = objectArr[i].components[ic].components[icc]
      target.more = undefined
      this.objectArrMap()
      this.setState({
        targetX: target,
        ix: i,
        icx: ic,
        iccx: icc,
      })
    }
    this.setState({
      toggleDeleteObj: !this.state.toggleDeleteObj,
    })
  }

  cntSection = async () => {
    this.props.dispatch(connecting(true))
    await connectSection(this.state.formConnectArr)
    this.props.dispatch(connecting(false));
    this.props.dispatch(toggleConnectSection(false));
  }

  cntField = async () => {
    this.props.dispatch(connecting(true))
    const { formsX, fCIndex, targetX } = this.state
    await connectField(formsX, fCIndex, targetX)
    this.props.dispatch(connecting(false))
    this.props.dispatch(toggleConnectField(false));
  }

  toggleModalConnectSection = (i) => {
    if(i>=0) {
      const objectArr = this.state.objectArr
      objectArr[i].more = undefined
      this.objectArrMap()
      this.setState({
        targetX: objectArr[i],
        formConnectArr: [],
      })
      axios.get(`${serverURL}/form/findForms`)
      .then(async res => {
        // console.log(res.data)
        this.mapSectionConnect(objectArr[i], res.data)
      })
    }

    this.props.dispatch(toggleConnectSection(!this.props.toggleConnectSection))
  }

  mapSectionConnect = (target, forms) => {
    var sectionConnectionList = forms.map(
      (item, i) => (
        // console.log(item),
        <div key={i} className="" style={{marginBottom:'5px', cursor:'pointer', fontSize:'14px'}}>
          <Form.Check
            inline
            label={item.formTitle}
            name={`group${i}`}
            type='checkbox'
            id={`inline-checkbox-${i}`}
            onChange={(e) => this.sectionConnectSelect(e, target, forms, i)}
          />
        </div>
      )
    )
    this.setState({ sectionConnectionList })
  }

  toggleModalConnectField = (i, ic, icc) => {
    if(i>=0) {
      const objectArr = this.state.objectArr
      const target = objectArr[i].components[ic].components[icc]
      // console.log(555, target)
      target.more = undefined
      this.objectArrMap()
      this.setState({
        fCIndex: {},
        targetX: target,
        ix: i,
        icx: ic,
        iccx: icc,
      })
      axios.get(`${serverURL}/form/findForms`)
      .then(async res => {
        // console.log(res.data)
        this.mapFieldConnect(target, res.data, i, ic)
      })
    }

    this.props.dispatch(toggleConnectField(!this.props.toggleConnectField));
  }

  mapFieldConnect = (target, forms, i, ic) => {
    var fieldConnectionList = forms.map(
      (item, i) => (
        // console.log(item),
        <div key={i} className="" style={{marginBottom:'5px'}}>
          <div className="d-flex" style={{width:'250px', alignItems:'center', cursor:'pointer'}} onClick={() => this.toggleFormCnt(target, forms, i)}>
            { item.toggleForm
              ?<FiMinusSquare/>
              :<FiPlusSquare/>
            }
            <Button variant='link' size="sm" style={{margin:'0px'}}>
              {item.formTitle}
            </Button>
          </div>
          { item.toggleForm &&
              item.form.map(
                (item, s) => (
                  <div key={s} className='d-flex' style={{fontSize:'14px', flexDirection:'column', marginLeft:'25px'}}>
                    <Form.Check
                      inline
                      label={item.label}
                      name={`group${i}`}
                      type='radio'
                      id={`inline-radio-${i}-${s}`}
                      onChange={() => this.fieldConnectSelect(target, forms, i, s)}
                    />
                  </div>
                )
              )
          }
        </div>
      )
    )
    this.setState({ fieldConnectionList })
  }

  fieldOrder = () => {
    const arr = this.state.objectArr
    var n = 1
    for(let i=0; i<arr.length; i++) {
      const arr2 = arr[i].components
      var type = arr2.length
      if(type===1) {

        const a = arr2[0].components
        for (var x = 0; x < a.length; x++) {
          a[x].order = n++
          // console.log(n, a[x]?.label, a[x]?.order)
        }

      } else if(type===2) {

        const a = arr2[0].components
        const b = arr2[1].components
        var maxLength = Math.max(a.length, b.length);
        for (var x = 0; x < maxLength; x++) {

          if (x < a.length)
            a[x].order = n++
            // console.log(n, a[x]?.label, a[x]?.order)
          if (x < b.length)
            b[x].order = n++
            // console.log(n, b[x]?.label, b[x]?.order)

        }
      }
    }

  }

  changeColumn = (i, type) => {
    const { objectArr, elmIdArr } = this.state
    const colN = objectArr[i].components.length

    if(colN!==type) {
      objectArr[i].more = undefined
      // console.log('colN: ', colN)
      var arr = objectArr[i].components
      const lx = arr[0].label
      const labelB = lx.substring(0, lx.length - 1) + 2

      if(type===1) {
        const a = arr[0].components
        const b = arr[1].components
        const ab = []

        var maxLength = Math.max(a.length, b.length);
        for (var x = 0; x < maxLength; x++) {
          if (x < a.length)
            ab.push(a[x]);
          if (x < b.length)
            ab.push(b[x]);
        }

        arr[0].components = ab
        arr.splice(1, 1)
        delete elmIdArr[`${labelB}`]
        // console.log(elmIdArr)
        this.objectArrMap()

      } else if(type===2) {

        const ab = arr[0].components
        var a = []
        var b = []
  
        for (var i = 0; i < ab.length; i++) {
          if (i % 2 === 0)
            a.push(ab[i]);
          else
            b.push(ab[i]);
        }
  
        const columnB = {
          type: 'col',
          label: labelB,
          components: b
        }
        arr[0].components = a
        arr.push(columnB)
        elmIdArr[labelB] = columnB
        this.objectArrMap()
      }
    }
  }

  sectionMoreFalse = (target) => {
    const arr = this.state.objectArr
    for(let i=0; i<arr.length; i++) {
      const more = arr[i].more
      if(more && target!==arr[i].label) arr[i].more = undefined
    }
  }

  moreFalse = (target) => {
    const arr = this.state.objectArr
    for(let i=0; i<arr.length; i++) {
      const arr2 = arr[i].components
      for(let i2=0; i2<arr2.length; i2++) {
        // console.log(arr2)
        const arr3 = arr2[i2].components
        for(let i3=0; i3<arr3.length; i3++) {
          const more = arr3[i3].more
          // console.log(arr3[i3].label, more)
          if(more && target!==arr3[i3].label) arr3[i3].more = undefined
        }
      }
    }
  }

  toggleMore = async (e, i, ic, icc) => {
    const objectArr = this.state.objectArr
    const target = objectArr[i].components[ic].components[icc]
    this.moreFalse(target.label)
    target.more = !target.more
    await this.setState({ moreTarget: e.target })
    this.objectArrMap()
  }

  toggleSectionMore = async (e, i) => {
    const objectArr = this.state.objectArr
    const target = objectArr[i]
    this.sectionMoreFalse(target.label)
    target.more = !target.more
    await this.setState({ sectionMoreTarget: e.target })
    this.objectArrMap()
  }

  deleteSection = async () => {
    const {objectArr, elmIdArr, ix} = this.state
    const target = objectArr[ix]
    delete elmIdArr[`${target.key}`]
    const targetComp = objectArr[ix].components
    for(var i=0; i<targetComp.length; i++) {
      delete elmIdArr[`${targetComp[i].key}`]
      const colComp = targetComp[i].components
      for(var c=0; c<colComp.length; c++) {
        delete elmIdArr[`${colComp[c].key}`]
      }
    }
    objectArr.splice(ix, 1)
    await this.objectArrMap()
    this.toggleDeleteSection()
  }

  requiredObj = async (i, ic, icc) => {
    const objectArr = this.state.objectArr
    const target = objectArr[i].components[ic].components[icc]
    target.more = undefined
    target.required = !target.required
    this.objectArrMap()
  }

  deleteObj = async () => {
    const {objectArr, elmIdArr, ix, icx, iccx} = this.state
    const target = objectArr[ix].components[icx].components
    delete elmIdArr[`${target[iccx].label}`]
    target.splice(iccx, 1)
    await this.objectArrMap()
    this.toggleDeleteObj()
  }

  getCol = (id) => {
    const objectArr = this.state.objectArr
    // console.log(objectArr)
    for(let i=0; i<objectArr.length; i++) {
      var sec = objectArr[i].components
      for(let s=0; s<sec.length; s++) {
        var col = sec[s].components
        for(let c=0; c<col.length; c++) {
          if(id===col[c].key) {
            return {
              s: i,
              i: c,
              key: sec[s].key,
              components:sec[s].components
            }
          }
        }
      }
    }
  }

  getComponents = (id) => {
    const objectArr = this.state.objectArr
    for(let i=0; i<objectArr.length; i++) {
      var sec = objectArr[i].components
      for(let ic=0; ic<sec.length; ic++) {
        if(id===sec[ic].key) {
          return {
            i,
            ic,
            target: sec[ic].components
          }
        }
      }
    }
  }

  getHeight = (type) => {
    var height
    switch (type) {
      case 'multiLine' :
        height = '80px'
        break;
      default:  height = ''
    }
    return height
  }

  toggleElm = async (e, id, type) => {
    if(
      ['input', 'popover', 'dropItem'].includes(e.target.id) || 
      e.target.id.substring(0, 5)==='input'
    ) {
    } else {
      const { labelNull, elmIdArr, formBoxScrollPos } = this.state
      if(labelNull) {
        this.onLabelNull(true)
      } else {
        var x, y, ei, ti, lastCol, elmX, elmZ, iZ, arr=[], componentsZ=[], colZ, preY, direction, pressing = true
        const heightIndex = this.getHeight(type)
        var xp = formBoxScrollPos
        var col = this.getCol(id)
        colZ = elmX = col.key
        componentsZ = col.components
        elmZ = col.components[col.i]
        iZ = col.i
  
        const elmnt = document.getElementById(id)
        const wx = elmnt.offsetWidth
        const hx = elmnt.offsetHeight
        const tx = elmnt.offsetTop
        const lx = elmnt.offsetLeft
        const bx = tx + hx
  
        var tdif = e.clientY - tx
        var ldif = e.clientX - lx
        elmnt.style.top = e.clientY - tdif - xp + "px";
        elmnt.style.left = e.clientX - ldif + "px";
  
        var objNull = document.createElement('div');
        objNull.id = 'objNull';
        objNull.className = "elm-placement";
        objNull.style.height = heightIndex;
  
        dragMouseDown()
        function dragMouseDown(e) {
          elmnt.style.position = 'absolute';
          elmnt.style.width = `${wx}px`;
          const parent = document.getElementById(col.key);
          parent.insertBefore(objNull, elmnt);
        }
  
        document.addEventListener('mousemove', (e) => {
          if (pressing) {
            x = e.clientX
            y = e.clientY
            elmnt.style.left = x - ldif + "px";
            elmnt.style.top = y - tdif - xp + "px";
  
            this.scrollBy(y)
            const newXp = this.state.formBoxScrollPos

            var index = 0
            // console.log(1111, elmIdArr)
  
            for(const [key, value] of Object.entries(elmIdArr)) {
              if(value.type==='sectionCol') {
                const left = elmIdArr[`${key}`].left
                const right = elmIdArr[`${key}`].right
                const top = elmIdArr[`${key}`].top
                const bottom = elmIdArr[`${key}`].bottom
  
                const newTop = top - newXp
                const newBottom = bottom - newXp
                const targetIndex = x > left && x < right && y > newTop && y < newBottom;
  
                if(targetIndex) {
                  index++
                  elmX = key
                }
  
              }
            }
  
            // console.log(elmX, lastCol)
            if(index===0) lastCol = ''
            if(index===1) {
              if(elmX!==lastCol) {
                var components = this.getComponents(elmX)
                arr = components.target
              }
            }
  
            if(y < preY) {
              direction='up'
            } else if(y > preY) {
              direction='down'
            }
  
            if(colZ===elmX) {
              // console.log('=======')
  
              for(let i=0; i<arr.length; i++) {
                const idx = arr[i].key
    
                const target = document.getElementById(idx)
                const topx = elmIdArr[`${idx}`].top
                const bottomx = elmIdArr[`${idx}`].bottom
  
                const newTop = topx - newXp
                const newBottom = bottomx - newXp
      
                const targetIndex = y > newTop && y < newBottom;
                if(targetIndex) {
  
                  ei = [...elmnt.parentNode.children].indexOf(elmnt)
                  ti = [...target.parentNode.children].indexOf(target)
  
                  removeElm('objNull')
                  // console.log(ei, ti)
  
                  if(ti>ei) {
                    //Append After:
                    target.parentNode.insertBefore(objNull, target.nextSibling);
                  } else {
                    //Append Before:
                    target.parentNode.insertBefore(objNull, target);
                  }
  
                }
              }
            } else {
              if(arr.length===0) {
                // console.log(arr)
                // console.log(elmX)
                removeElm('objNull')
                document.getElementById(elmX).appendChild(objNull)
              } else {
                for(let i=0; i<arr.length; i++) {
                  const idx = arr[i].key
  
                  const target = document.getElementById(idx)
                  const topx = elmIdArr[`${idx}`].top
                  const bottomx = elmIdArr[`${idx}`].bottom
    
                  const newTop = topx - newXp
                  const newBottom = bottomx - newXp
    
                  // console.log(direction)
                  const inx = direction==='down' ? 60 : -10
                  const targetIndex = y > newTop + inx && y < newBottom + inx;
                  if(targetIndex) {
                    // console.log(true)
                    
                    ei = [...elmnt.parentNode.children].indexOf(elmnt)
                    ti = [...target.parentNode.children].indexOf(target)
                    
                    removeElm('objNull')
                    // console.log(direction)
                    if(direction==='down') {
                      //Append After:
                      ti = ti + 1
                      target.parentNode.insertBefore(objNull, target.nextSibling);
                    } else {
                      //Append Before:
                      ti = ti - 1
                      target.parentNode.insertBefore(objNull, target);
                    }
    
                  } else if(elmX!==lastCol) {
                    removeElm('objNull')
                    var id = arr[arr.length-1].key
                    const bottom = elmIdArr[`${id}`].bottom
                    if(bottom - newXp < y) {
                      // console.log(111111)
                      const target = document.getElementById(id)
                      ti = [...target.parentNode.children].indexOf(target)
                      ti = ti + 1
                      target.parentNode.insertBefore(objNull, target.nextSibling);
                    } else {
                      // console.log(222222)
                      var id = arr[0].key
                      const target = document.getElementById(id)
                      ti = 0
                      target.parentNode.insertBefore(objNull, target);
                    }

                  }

                }
              }

            }
            lastCol = elmX

          }
          preY = y
        }, false);

        elmnt.addEventListener('mouseup', async (e) => {
          if(pressing){
            // ti = direction==='up' ? ti - 1 : ti
            // console.log(direction, ti)
            removeElm('objNull')
            if(colZ===elmX) {
              if(ei!==ti) {
                const components = col.components
                if(ei<ti) {
                  const eElm = components[ei]
                  components.splice(ei, 1)
                  components.splice(ti, 0, eElm)
                } else {
                  ei--
                  ti--
                  // console.log(ei, ti)
                  const eElm = components[ei]
                  components.splice(ti, 0, eElm)
                  components.splice(ei+1, 1)
                  // console.log(components)
                }
                // console.log(components)
                this.objectArrMap()
              }
            } else {
              const elmA = componentsZ.splice(iZ, 1)
              arr.splice(ti, 0, elmA[0])
            }
  
            elmnt.style.position = '';
            elmnt.style.width = `100%`;
            this.objectArrMap()
  
            pressing = false;
          }
        }, false);
  
      }
    }
  }

  toggleProperties = async (type, i, ic, icc) => {
    const objectArr = this.state.objectArr
    const target = objectArr[i].components[ic].components[icc]
    target.more = undefined
    // console.log(88, type, target)
    if(type!=='lookup') {
      const options = target.options
      var  index = 0
      for(let x=0; x<options.length; x++) {
        if(options[x].defaultValue) {
          this.setState({
            optionDefaultValue: options[x].label
          })
          index++
        }
      }
      if(index===0) {
        this.setState({
          optionDefaultValue: false
        })
      }
      this.setState({
        togglePickList: !this.state.togglePickList,
        pickList: {
          i, ic, icc,
          key: target.key,
          label: target.label,
        }
      })
      this.optionArrMap(objectArr, i, ic, icc)
    } else {
      this.setState({
        toggleLookup: !this.state.toggleLookup,
        lookup: {
          i, ic, icc,
          key: target.key,
          label: target.label,
        }
      })
      const formId = target.formId
      const fieldId = target.fieldId
      this.getForms(formId, fieldId)
    }
    this.objectArrMap()

    // console.log(this.state.pickList)
  }

  getForms = (formId, fieldId) => {
    var formArr, fieldArr
    axios.get(`${serverURL}/form/findForms`)
    .then(async res => {
      // console.log(res.data)
      formArr = res.data
      if(formArr.length>0) {
        if(formId) {
          // console.log(1)
          for(let i=0; i<formArr.length; i++) {
            if(formArr[i]._id===formId) {
              fieldArr = await this.getFields(formArr[i].form)
              formArr[i].defaultValue = true
              if(fieldId) {
                for(let i=0; i<fieldArr.length; i++) {
                  if(fieldArr[i].key===fieldId) {
                    fieldArr[i].defaultValue = true
                    this.setState({
                      lookupField: fieldArr[i].label
                    })
                  }
                }
              } else {
                fieldArr[0].defaultValue = true
              }
              this.setState({
                formArr, fieldArr,
                lookupForm: formArr[i].formTitle,
                // lookupField: fieldArr[0].label
              })
            }
          }
        } else {
          // console.log(2)
          fieldArr = await this.getFields(formArr[0].form)
          formArr[0].defaultValue = true
          fieldArr[0].defaultValue = true
          this.setState({
            formArr, fieldArr,
            lookupForm: formArr[0].formTitle,
            lookupField: fieldArr[0].label
          })
        }
      }
    })
  }

  getFields = async (arr) => {
    var fields = []
    for(let i=0; i<arr.length; i++) {
      const arr2 = arr[i].components
      for(let i2=0; i2<arr2.length; i2++) {
        const arr3 = arr2[i2].components
        for(let i3=0; i3<arr3.length; i3++) {
          var field = arr3[i3]
          fields.push(field)
        }
      }
    }
    fields.sort((a, b) => a.order - b.order)
    return fields
  }

  changePickListLabel = async (e) => {
    const { objectArr, pickList } = this.state
    const { key, i, ic, icc } = this.state.pickList
    const target = objectArr[i].components[ic].components[icc]
    const label = e.target.value

    target.label = label
    this.setState({
      pickList: { label, key, i, ic, icc }
    })

    const elmnt =  document.getElementById(`input-${i}-${ic}-${icc}`)
    elmnt.value = label
  }

  changeMultiSelectLabel = async (e) => {
    const { objectArr, pickList } = this.state
    const { key, i, ic, icc } = this.state.pickList
    const target = objectArr[i].components[ic].components[icc]
    const label = e.target.value

    target.label = label
    this.setState({
      multiSelect: { label, key, i, ic, icc }
    })

    const elmnt =  document.getElementById(`input-${i}-${ic}-${icc}`)
    elmnt.value = label
  }

  changeLookupLabel = async (e) => {
    const { objectArr } = this.state
    const { key, i, ic, icc } = this.state.lookup
    const target = objectArr[i].components[ic].components[icc]
    const label = e.target.value

    target.label = label
    this.setState({
      lookup: { label, key, i, ic, icc }
    })

    const elmnt =  document.getElementById(`input-${i}-${ic}-${icc}`)
    elmnt.value = label
  }

  optionClick = async (iX) => {
    var optionArr = this.state.optionArr
    for(var i=0; i<optionArr.length; i++) {
      optionArr[i].defaultValue = undefined
    }

    if(iX==='none') {
      this.setState({
        optionDefaultValue: false
      })
    } else {
      optionArr[iX].defaultValue = true
      this.setState({
        optionDefaultValue: optionArr[iX].label
      })
    }
  }

  lookupFormClick = async (iX, form) => {
    // console.log(form)
    const formId = form._id
    const { objectArr, formArr } = this.state
    const { i, ic, icc } = this.state.lookup
    const target = objectArr[i].components[ic].components[icc]

    for(let a=0; a<formArr.length; a++) {
      formArr[a].defaultValue = undefined
    }

    formArr[iX].defaultValue = true
    target.formId = formId
    this.setState({
      lookupForm: formArr[iX].formTitle
    })

    var fieldArr = await this.getFields(form.form)
    fieldArr[0].defaultValue = true
    this.setState({
      fieldArr,
      lookupField: fieldArr[0].label
    })
  }

  lookupFormClick = async (iX, form) => {
    // console.log(form)
    const formId = form._id
    const { objectArr, formArr } = this.state
    const { i, ic, icc } = this.state.lookup
    const target = objectArr[i].components[ic].components[icc]

    for(let a=0; a<formArr.length; a++) {
      formArr[a].defaultValue = undefined
    }

    formArr[iX].defaultValue = true
    target.formId = formId
    this.setState({
      lookupForm: formArr[iX].formTitle
    })

    var fieldArr = await this.getFields(form.form)
    fieldArr[0].defaultValue = true
    this.setState({
      fieldArr,
      lookupField: fieldArr[0].label
    })
  }

  lookupFieldClick = async (iX, key) => {
    // console.log(key)
    // const formId = form._id
    const { objectArr, fieldArr } = this.state
    const { i, ic, icc } = this.state.lookup
    const target = objectArr[i].components[ic].components[icc]

    for(let a=0; a<fieldArr.length; a++) {
      fieldArr[a].defaultValue = undefined
    }

    fieldArr[iX].defaultValue = true
    target.fieldId = key
    this.setState({
      lookupField: fieldArr[iX].label
    })
  }

  changeLabel = async (e, type, objectArr, i, ic, icc) => {
    var fx = e.target.selectionStart
    var tx = e.target.value
    if(type==='section') {
      var id = `input-${i}`
      objectArr[i].label = tx
    } else {
      var id = `input-${i}-${ic}-${icc}`
      objectArr[i].components[ic].components[icc].label = tx
    }

    this.setState({ labelNull: tx === '' ? true : false });
    await this.objectArrMap()
    let text = document.getElementById(id);
    if(text) text.setSelectionRange(fx, fx)

    // console.log(this.state.objectArr)
  };

  changeOptionLabel = async (e, objectArr, i, ic, icc, ix) => {
    var fx = e.target.selectionStart
    var tx = e.target.value
    const target = objectArr[i].components[ic].components[icc].options[ix]
    target.label = tx
    if(target.defaultValue) {
      this.setState({ optionDefaultValue: tx })
    }
    var id = `${target.key}-input`
    this.setState({ labelNull: tx === '' ? true : false });
    await this.optionArrMap(objectArr, i, ic, icc)
    let text = document.getElementById(id);
    if(text) text.setSelectionRange(fx, fx)
  };

  onLabelNull = (status) => {
    this.setState({ alertLabelNull: status });
  }

  onSaveComplete = (status) => {
    this.setState({ saveComplete: status });
  }

  changeHandler = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  countControl = async (type, t) => {
    const { objN } = this.state;
    const obj = ['section', 'singleLine', 'multiLine', 'number', 'email', 'checkbox',
    'longInteger', 'date', 'datetime', 'percent', 'currency', 'phone', 'url', 'pickList',
    'multiSelect', 'imageUpload', 'fileUpload', 'lookup'];
    if (obj.includes(type)) {
        objN[type] += t;
    }
  }

  countSectionFields = (section) => {
    let n = 0
    var col = section.components
    for(let i=0; i<col.length; i++) {
      n = n + col[i].components.length
    }
    return n
  }

  createElm = async (type) => {
    if(this.state.labelNull) {
      this.onLabelNull(true)
    } else {
      this.setState({
        elmType: type,
      })
      await this.countControl(type, 1)
      const objArr = this.state.objArr

      const elmData = this.getElmData(type)
      const elm = elmData.elm
      const n = elmData.n

      objArr.push(elm)
      await this.setState({ objArr })
      // console.log(this.state.objArr)

      const leftPosHand = 70
      await this.activatedElement(type, n, leftPosHand)
    }
  }

  activatedElement = async (type, n, leftPosHand) => {
    var x, y, preY, ti = 0, target={}, direction, lastCol, arr=[], pressing = true, blueElm = false
    const heightIndex = this.getHeight(type)

    const elmnt =  document.getElementById(`${type}${n}`)

    var objNull = document.createElement('div');
    objNull.id = 'objNull';
    objNull.className = type==='section' ? 'section-placement' : 'elm-placement';
    objNull.style.height = type==='section' ? '' : heightIndex;

    dragMouseDown();
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      x = e.clientX;
      y = e.clientY;
      elmnt.style.left = (x) - leftPosHand + "px";
      elmnt.style.top = (y) - (elmnt.offsetHeight/2) + "px";
    }

    document.addEventListener('mousemove', (e) => {
      if (pressing) {
        const elmIdArr = this.state.elmIdArr
        // console.log(elmIdArr)
        e = e || window.event;
        e.preventDefault();
        x = e.clientX
        y = e.clientY
  
        elmnt.style.left = (x) - leftPosHand + "px";
        elmnt.style.top = (y) - (elmnt.offsetHeight/2) + "px";
  
        if(y < preY) {
          direction='up'
        } else if(y > preY) {
          direction='down'
        }

        const fx = elmIdArr.formBox
        const formIndex = x > fx.left && x < fx.right && y > fx.top && y < fx.bottom;

        if(type==='section') {
          var index = 0, elmX = ''
          if(formIndex) {
            index++
            elmX = 'formBox'
          }
          if(index===0) lastCol = ''
          if(index===1) {
            if(elmX!==lastCol) {
              arr = this.state.objectArr
            }
            if(arr.length===0) {
              document.getElementById('formBox').appendChild(objNull)
            } else {
              this.scrollBy(y)
              const newXp = this.state.formBoxScrollPos
              for(let i=0; i<arr.length; i++) {
                const idx = arr[i].key
                // console.log('idx: ', idx)
                const target = document.getElementById(idx)
                const topx = elmIdArr[`${idx}`].top
                const bottomx = elmIdArr[`${idx}`].bottom
  
                const newTop = topx - newXp
                const newBottom = bottomx - newXp
  
                const inx = direction==='down' ? 60 : -10
                const targetIndex = y > newTop + inx && y < newBottom + inx;

                if(targetIndex) {
                  // console.log(1)
                  ti = [...target.parentNode.children].indexOf(target)
                  removeElm('objNull')
                  if(direction==='down') {
                    //Append After:
                    ti = ti + 1
                    target.parentNode.insertBefore(objNull, target.nextSibling);
                  } else {
                    //Append Before:
                    ti = ti - 1
                    target.parentNode.insertBefore(objNull, target);
                  }
                } else if(elmX!==lastCol) {
                  // console.log(2)
                  removeElm('objNull')
                  var id = arr[arr.length-1].key
                  const bottom = elmIdArr[`${id}`].bottom
                  if(bottom - newXp < y) {
                    // console.log(id)
                    const target = document.getElementById(id)
                    ti = [...target.parentNode.children].indexOf(target)
                    ti = ti + 1
                    target.parentNode.insertBefore(objNull, target.nextSibling);
                  } else {
                    var id = arr[0].key
                    const target = document.getElementById(id)
                    ti = 0
                    target.parentNode.insertBefore(objNull, target);
                  }
                  // console.log('tix: ', ti)

                }
                lastCol = elmX
              }
            }
            blueElm = true
          } else {
            removeElm('objNull')
            blueElm = false
          }

        } else {

          if(formIndex) this.scrollBy(y)
          var newXp = this.state.formBoxScrollPos
          var index = 0, elmX = ''
          for(const [key, value] of Object.entries(elmIdArr)) {
            if(value.type==='sectionCol') {
              const left = elmIdArr[`${key}`].left
              const right = elmIdArr[`${key}`].right
              const top = elmIdArr[`${key}`].top
              const bottom = elmIdArr[`${key}`].bottom

              const newTop = top - newXp
              const newBottom = bottom - newXp
              const targetIndex = x > left && x < right && y > newTop && y < newBottom;

              if(targetIndex) {
                index++
                elmX = key
              }
            }
          }

          if(index===0) lastCol = ''
          if(index===1) {
            if(elmX!==lastCol) {
              var components = this.getComponents(elmX)
              arr = components.target
              target = components
            }
            if(arr.length===0) {
              document.getElementById(elmX).appendChild(objNull)
            } else {
              for(let i=0; i<arr.length; i++) {
                const idx = arr[i].key
                const target = document.getElementById(idx)
                const topx = elmIdArr[`${idx}`].top
                const bottomx = elmIdArr[`${idx}`].bottom

                const newTop = topx - newXp
                const newBottom = bottomx - newXp

                const inx = direction==='down' ? 60 : -10
                const targetIndex = y > newTop + inx && y < newBottom + inx;

                // console.log('ti: ', ti)

                if(targetIndex) {
                  ti = [...target.parentNode.children].indexOf(target)
                  removeElm('objNull')
                  // console.log(direction)
                  if(direction==='down') {
                    //Append After:
                    ti = ti + 1
                    target.parentNode.insertBefore(objNull, target.nextSibling);
                  } else {
                    //Append Before:
                    ti = ti - 1
                    target.parentNode.insertBefore(objNull, target);
                  }
                } else if(elmX!==lastCol) {
                  removeElm('objNull')
                  var id = arr[arr.length-1].key
                  const bottom = elmIdArr[`${id}`].bottom
                  if(bottom - newXp < y) {
                    const target = document.getElementById(id)
                    ti = [...target.parentNode.children].indexOf(target)
                    ti = ti + 1
                    target.parentNode.insertBefore(objNull, target.nextSibling);
                  } else {
                    var id = arr[0].key
                    const target = document.getElementById(id)
                    ti = 0
                    target.parentNode.insertBefore(objNull, target);
                  }
                }
                lastCol = elmX
              }
            }
            blueElm = true
          } else {
            removeElm('objNull')
            blueElm = false
          }
        }

      }
      preY = y
    }, false);


    elmnt.addEventListener('mouseup', async (e) => {
      if(pressing){
        const date = new Date();
        const dateN = date.getTime();

        removeElm('objNull')
        var { objN } = this.state
        this.setState({ objArr: [] })

        const elementTypes = {
          section: {
            type: 'section',
            label: `Section${objN.section}`,
            components: [
              { type: 'col', key: `sectionCol1-${dateN}`, label: `sectionCol${objN.section}1`, components: [] },
              { type: 'col', key: `sectionCol2-${dateN}`, label: `sectionCol${objN.section}2`, components: [] },
            ],
          },
          singleLine: {
            type: 'singleLine',
            name: 'Single Line',
            label: `SingleLine${objN.singleLine}`,
          },
          multiLine: {
            type: 'multiLine',
            name: 'Multi Line',
            label: `MultiLine${objN.multiLine}`,
          },
          number: {
            type: 'number',
            name: 'Number',
            label: `Number${objN.number}`,
          },
          email: {
            type: 'email',
            name: 'Email',
            label: `Email${objN.email}`,
          },
          checkbox: {
            type: 'checkbox',
            name: 'Checkbox',
            label: `Checkbox${objN.checkbox}`,
          },
          longInteger: {
            type: 'number',
            name: 'Long Integer',
            label: `LongInteger${objN.longInteger}`,
          },
          date: {
            type: 'date',
            name: 'Date',
            label: `Date${objN.date}`,
          },
          datetime: {
            type: 'datetime',
            name: 'Date/Time',
            label: `Date/Time${objN.datetime}`,
          },
          percent: {
            type: 'percent',
            name: 'Percent',
            label: `Percent${objN.percent}`,
          },
          currency: {
            type: 'currency',
            name: 'Currency',
            label: `Currency${objN.currency}`,
          },
          phone: {
            type: 'phone',
            name: 'Phone',
            label: `Phone${objN.phone}`,
          },
          url: {
            type: 'url',
            name: 'URL',
            label: `URL${objN.url}`,
          },
          pickList: {
            type: 'pickList',
            name: 'Pick List',
            label: `PickList${objN.pickList}`,
            options: [
              {
                "key": `option-${dateN}`,
                "order": 0,
                "type": "option",
                "name": "Option",
                "label": "Option 1"
              },
              {
                "key": `option-${dateN + 1}`,
                "order": 0,
                "type": "option",
                "name": "Option",
                "label": "Option 2"
              }
            ]
          },
          multiSelect: {
            type: 'multiSelect',
            name: 'Multi Select',
            label: `MultiSelect${objN.multiSelect}`,
            options: [
              {
                "key": `option-${dateN}`,
                "order": 0,
                "type": "option",
                "name": "Option",
                "label": "Option 1"
              },
              {
                "key": `option-${dateN + 1}`,
                "order": 0,
                "type": "option",
                "name": "Option",
                "label": "Option 2"
              }
            ]
          },
          imageUpload: {
            type: 'imageUpload',
            name: 'Image Upload',
            label: `ImageUpload${objN.imageUpload}`,
            options: [],
            value: [],
            deletedItems: [],
          },
          fileUpload: {
            type: 'fileUpload',
            name: 'File Upload',
            label: `FileUpload${objN.fileUpload}`,
            options: [],
            value: [],
            deletedItems: [],
          },
          lookup: {
            type: 'lookup',
            name: 'Lookup',
            label: `Lookup${objN.lookup}`,
            formId: '',
            fieldId: '',
            formDataId: '',
          }
        };

        const newElementType = elementTypes[type];

        if (newElementType && blueElm) {
          const newElm = {
            key: `${newElementType.type}-${dateN}`,
            order: 0,
            required: false,
            ...newElementType,
          };
          arr.splice(ti, 0, newElm);
          this.objectArrMap();
          if(type==='pickList') {
            this.setState({
              togglePickList: !this.state.togglePickList,
              pickList: {
                label: newElementType.label
              },
              optionDefaultValue: false
            })

            function getIcc() {
              for(let a=0; a<arr.length; a++) {
                if(newElm.key===arr[a].key) {
                  return a
                }
              }
            }
            const objectArr = this.state.objectArr
            const { i, ic} = target
            const icc = getIcc()
            this.optionArrMap(objectArr, i, ic, icc)
            this.optionBoxScroll()
            this.setState({
              pickList: {
                i, ic, icc,
                key: target.key,
                label: target.label,
              }
            })
          }

          if(type==='multiSelect') {
            this.setState({
              toggleMultiSelect: !this.state.toggleMultiSelect,
              multiSelect: {
                label: newElementType.label
              },
              optionDefaultValue: false
            })

            function getIcc() {
              for(let a=0; a<arr.length; a++) {
                if(newElm.key===arr[a].key) {
                  return a
                }
              }
            }
            const objectArr = this.state.objectArr
            const { i, ic} = target
            const icc = getIcc()
            this.optionArrMap(objectArr, i, ic, icc)
            this.optionBoxScroll()
            this.setState({
              multiSelect: {
                i, ic, icc,
                key: target.key,
                label: target.label,
              }
            })
          }
          if(type==='lookup') {
            this.setState({
              toggleLookup: !this.state.toggleLookup,
              lookup: {
                label: newElementType.label
              },
            })
            this.getForms()
            function getIcc() {
              for(let a=0; a<arr.length; a++) {
                if(newElm.key===arr[a].key) {
                  return a
                }
              }
            }
            const objectArr = this.state.objectArr
            const { i, ic} = target
            const icc = getIcc()
            // this.optionArrMap(objectArr, i, ic, icc)
            // this.optionBoxScroll()
            this.setState({
              lookup: {
                i, ic, icc,
                key: target.key,
                label: target.label,
              }
            })
          }
        } else {
          this.countControl(type, -1);
        }

        pressing = false;

      }
    }, false);

  }

  onFormList = () => {
    this.setState({ mode: 'list' })
    axios.get(`${serverURL}/form/findForms`)
    .then(async res => {
      // console.log(res.data)
      this.mapFormList(res.data)
    })
  }

  onFormShow = async (item, mode) => {
    // console.log(item)
    await this.setState({
      idX: item._id,
      formTitle: item.formTitle,
      objectArr: item.form,
      saveType: 'edit',
      mode,
      elmIdArr: {formBox: {type:'form'}},
    })
    await this.getObjN()
    if(mode==='builder') this.objectArrMap()
    if(mode==='preview') previewArrMap(item.form, false).then(res => {
      const { formPreview, error, multiSelectArr } = res
      this.setState({ formPreview, error })
      setMultiSelect(multiSelectArr)
    })
    this.formBoxScroll()
  }

  onFormDelete = (item) => {
    axios.post(`${serverURL}/form/delete`, item)
    .then(async res => {
      await this.onFormList()
      this.setState({
        toggleDeleteForm: !this.state.toggleDeleteForm,
      })  
    })
  }

  mapFormList = (forms) => {
    var formsArray = forms.map (
      (item, i) => (
        // console.log(item),
        <div key={i} className="d-flex" style={{alignItems:'center', marginBottom:'10px'}}>
          <div className="d-flex" style={{width:'250px', alignItems:'center'}}>
            <div style={{fontSize:''}}>{ i + 1 }</div>
            <Button variant='link' size="sm" style={{margin:'0px'}} onClick={() => this.onFormShow(item, 'preview')}>
              {item.formTitle}
            </Button>
          </div>
          <Button variant='outline-primary' size="sm" style={{margin:'5px', padding:'0px 8px'}} onClick={() => this.onFormShow(item, 'builder')}>
            Edit
          </Button>
          <Button variant='outline-danger' size="sm" style={{margin:'5px', padding:'0px 8px'}} onClick={() => this.toggleDeleteForm(item)}>
            Delete
          </Button>
        </div>
      )
    )
    this.setState({ formList: formsArray })
  }

  onNewForm = async () => {
    await this.setState({
      saveType: 'new',
      mode: 'builder',
      formTitle: '',
      elmIdArr: {formBox: {type:'form'}},
      objArr: [],
      objectArr: [],
      objN: {
        section: 0,
        singleLine: 0,
        multiLine: 0,
        number: 0,
        email: 0,
        checkbox: 0,
        longInteger: 0,
        date: 0,
        datetime: 0,
        percent: 0,
        currency: 0,
        phone: 0,
        url: 0,
        pickList: 0,
        multiSelect: 0,
        imageUpload: 0,
        fileUpload: 0,
        lookup: 0,
      }
    })
    this.objectArrMap()
    var elmnt = document.getElementById('builderBtn');
    elmnt.focus();
  }

  onBuilder () {
    this.objectArrMap()
    this.setState({
      mode: 'builder',
    })
  }

  onPreview () {
    previewArrMap(this.state.objectArr, false).then(res => {
      const { formPreview, error } = res
      this.setState({ formPreview, error })
    })
    this.setState({
      mode: 'preview',
    })
  }

  onSave = async () => {
    if(this.state.labelNull) {
      this.setState({ alertLabelNull: true });
    } else {
      this.setState({ saveComplete: false })
      await this.fieldOrder()
      const { idX, formTitle, objectArr, saveType } = this.state
      const data = {
        idX,
        formTitle,
        form: objectArr
      }
      // console.log(data)
      axios.post(`${serverURL}/form/${saveType==='new' ? 'new' : 'edit'}`, data)
      .then(async res => {
        this.setState({ saveComplete: true })
        // console.log(res.data)
        // this.onFormList()
      })
    }
  }

  onResize = () => {
    this.setState({ 
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  render() {
    const { w, h, optionArr, formArr, fieldArr, optionDefaultValue, lookupForm, lookupField, options, staticObj, pickList, multiSelect, lookup, togglePickList, toggleMultiSelect, toggleLookup, toggleDeleteForm, fieldConnectionList, sectionConnectionList, saveComplete, formPreview, formList, saving, mode, type, formBuilderRef, formListRef, formTitle, objects, objArr, objectArr, targetX, elmIdArr, alertLabelNull, toggleDeleteSection, toggleDeleteObj, elmType } = this.state
    const { connecting, toggleConnectSection, toggleConnectField } = this.props

    const modalPickList = (
      <Modal show={togglePickList} onHide={this.toggleModalPickList}>
        <Modal.Header style={{padding:'6px 16px'}}>
          <Modal.Title>Pick List Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex' style={{ fontSize:'14px', marginBottom:'20px' }}>
            <div style={{ whiteSpace:'nowrap', marginRight:'15px' }}>
              <span>Field Label</span>
              <span className='font-red'>*</span>
            </div>
            <input type="text" className='elm-input form-control'
              style={{width:'100%', color:'#000000'}}
              value={pickList?.label}
              onChange={(e) => this.changePickListLabel(e)}
            />
          </div>
          <div style={{ marginBottom:'20px' }}>
            <div style={{marginBottom:'5px'}}>Pick List Option</div>
            <div id='optionBox' className='option-box' style={{}}>
              {options}  
            </div>
          </div>
          <div style={{ fontSize:'14px', marginBottom:'5px' }}>Select default value</div>
          <DropdownButton
            as={ButtonGroup}
            size='sm'
            // key='Primary'
            // id={`dropdown-variants-${variant}`}
            variant=''
            title={optionDefaultValue ? optionDefaultValue : '-None-'}
            // className="custom-dropdown"
            // style={{fontSize:'10px'}}
          >
            <Dropdown.Item eventKey={0} active={false} onClick={() => this.optionClick('none')}>-None-</Dropdown.Item>
            { optionArr &&
              optionArr.map(
                (itemX, iX) => (
                  <Dropdown.Item key={iX} eventKey={iX} active={itemX.defaultValue} onClick={() => this.optionClick(iX)}>{itemX.label}</Dropdown.Item>
                )
              )
            }
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.onOptionDone('toggleLookup')}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalMultiSelect = (
      <Modal show={toggleMultiSelect} onHide={this.toggleModalMultiSelect}>
        <Modal.Header style={{padding:'6px 16px'}}>
          <Modal.Title>Multi Select Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex' style={{ fontSize:'14px', marginBottom:'20px' }}>
            <div style={{ whiteSpace:'nowrap', marginRight:'15px' }}>
              <span>Field Label</span>
              <span className='font-red'>*</span>
            </div>
            <input type="text" className='elm-input form-control'
              style={{width:'100%', color:'#000000'}}
              value={multiSelect?.label}
              onChange={(e) => this.changeMultiSelectLabel(e)}
            />
          </div>
          <div style={{ marginBottom:'20px' }}>
            <div style={{marginBottom:'5px'}}>Pick List Option</div>
            <div id='optionBox' className='option-box' style={{}}>
              {options}  
            </div>
          </div>
          <div style={{ fontSize:'14px', marginBottom:'5px' }}>Select default value</div>
          <DropdownButton
            as={ButtonGroup}
            size='sm'
            // key='Primary'
            // id={`dropdown-variants-${variant}`}
            variant=''
            title={optionDefaultValue ? optionDefaultValue : '-None-'}
            // className="custom-dropdown"
            // style={{fontSize:'10px'}}
          >
            <Dropdown.Item eventKey={0} active={false} onClick={() => this.optionClick('none')}>-None-</Dropdown.Item>
            { optionArr &&
              optionArr.map(
                (itemX, iX) => (
                  <Dropdown.Item key={iX} eventKey={iX} active={itemX.defaultValue} onClick={() => this.optionClick(iX)}>{itemX.label}</Dropdown.Item>
                )
              )
            }
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.onOptionDone('toggleMultiSelect')}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalLookup = (
      <Modal show={toggleLookup} onHide={this.toggleModalLookup}>
        <Modal.Header style={{padding:'6px 16px'}}>
          <Modal.Title>Lookup Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex' style={{ fontSize:'14px', marginBottom:'30px' }}>
            <div style={{ whiteSpace:'nowrap', marginRight:'15px' }}>
              <span>Field Label</span>
              <span className='font-red'>*</span>
            </div>
            <input type="text" className='elm-input form-control'
              style={{width:'100%', color:'#000000'}}
              value={lookup?.label}
              onChange={(e) => this.changeLookupLabel(e)}
            />
          </div>
          <div style={{ fontSize:'14px', marginBottom:'20px 0px 5px' }}>Select lookup form</div>
          <DropdownButton
            as={ButtonGroup}
            size='sm'
            // key='Primary'
            // id={`dropdown-variants-${variant}`}
            variant=''
            title={lookupForm ? lookupForm : '-None-'}
            // className="custom-dropdown"
            // style={{fontSize:'10px'}}
          >
            { formArr &&
              formArr.map(
                (itemX, iX) => (
                  // console.log(itemX),
                  <Dropdown.Item key={iX} eventKey={iX} active={itemX.defaultValue} onClick={() => this.lookupFormClick(iX, itemX)}>{itemX.formTitle}</Dropdown.Item>
                )
              )
            }
          </DropdownButton>
          <div style={{ fontSize:'14px', margin:'20px 0px 5px' }}>Select lookup field</div>
          <DropdownButton
            as={ButtonGroup}
            size='sm'
            // key='Primary'
            // id={`dropdown-variants-${variant}`}
            variant=''
            title={lookupField ? lookupField : '-None-'}
            // className="custom-dropdown"
            // style={{fontSize:'10px'}}
          >
            { fieldArr &&
              fieldArr.map(
                (itemX, iX) => (
                  // console.log(itemX),
                  <Dropdown.Item key={iX} eventKey={iX} active={itemX.defaultValue} onClick={() => this.lookupFieldClick(iX, itemX.key)}>{itemX.label}</Dropdown.Item>
                )
              )
            }
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.onOptionDone('toggleLookup')}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalConnectSection = (
      <Modal show={toggleConnectSection} onHide={this.toggleModalConnectSection}>
        <Modal.Header>
          <Modal.Title>{`Connection - ${targetX?.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sectionConnectionList}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.cntSection}>
            Connect
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalConnectField = (
      <Modal show={toggleConnectField} onHide={this.toggleModalConnectField}>
        <Modal.Header>
          <Modal.Title>{`Connection - ${targetX?.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fieldConnectionList}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.cntField}>
            Connect
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalDeleteForm = (
      <Modal show={toggleDeleteForm} onHide={this.toggleDeleteForm}>
        <Modal.Header>
          <Modal.Title>{`Remove Form - ${targetX?.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure about deleting this form and all its fields?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleDeleteForm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>this.onFormDelete(targetX)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalDeleteSection = (
      <Modal show={toggleDeleteSection} onHide={this.toggleDeleteSection}>
        <Modal.Header>
          <Modal.Title>{`Remove Section - ${targetX?.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure about deleting this section and all its fields?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleDeleteSection}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.deleteSection}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalDeleteObj = (
      <Modal show={toggleDeleteObj} onHide={this.toggleDeleteObj}>
        <Modal.Header>
          <Modal.Title>{`Remove Field - ${targetX?.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>The field you are about to remove from this layout can either be deleted or moved to the Unused Fields section in the left panel. What do you prefer to do?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleDeleteObj}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.deleteObj}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalLabelNull = (
      <Modal show={alertLabelNull} size="sm" onHide={this.onLabelNull}>
        <Modal.Header style={{padding:'5px 16px', fontWeight:'bold', borderTop:'5px solid red'}}>
          <div>Label Null</div>
          <div className='close-cross' onClick={()=>this.onLabelNull(false)}>×</div>
        </Modal.Header>
        <Modal.Body>Label can not be null.</Modal.Body>
      </Modal>
    )

    const modalSaveComplete = (
      <Modal show={saveComplete} size="sm" onHide={this.onSaveComplete}>
        <Modal.Header style={{padding:'5px 16px', fontWeight:'bold', borderTop:'5px solid green'}}>
          <div>Save Complete</div>
          <div className='close-cross' onClick={()=>this.onSaveComplete(false)}>×</div>
        </Modal.Header>
        <Modal.Body className='center justify-content-between'>
        <div>Form is successfully saved.</div>
        <Button size="sm" variant='success' onClick={() => this.onSaveComplete(false)}>
          OK
        </Button>
      </Modal.Body>

      </Modal>
    )

    const newFormBtn = (
      <Button variant='outline-primary' size="sm" style={{margin:'5px'}} onClick={() => this.onNewForm()}>
        New
      </Button>
    )
  
    const saveFormBtn = (
      <Button variant='outline-success' size="sm" disabled={['list', 'preview'].includes(mode) ? true : false} style={{margin:'5px'}} onClick={() => this.onSave()}>
        {type==='new' ? 'Save Form' : 'Save Changes'}
      </Button>
    )
  
    const builderBtn = (
      <Button id='builderBtn' variant={mode==='builder' ? 'primary' : 'outline-primary'} size="sm" style={{margin:'5px'}} onClick={() => this.onBuilder()}>
        Builder
      </Button>
    )
  
    const previewBtn = (
      <Button variant={mode==='preview' ? 'primary' : 'outline-primary'} size="sm" style={{margin:'5px'}} onClick={() => this.onPreview()}>
        Preview
      </Button>
    )
  
    const formListBtn = (
      <Button id='formListBtn' variant={mode==='list' ? 'dark' : 'outline-dark'} size="sm" style={{margin:'5px'}} onClick={() => this.onFormList()}>
        Form List
      </Button>
    )

    const formTitleInput = (
      <InputGroup className="mb-3">
        <InputGroup.Text id="formTitleInput">Form Title</InputGroup.Text>
        <Form.Control
          type='text'
          value={formTitle}
          name='formTitle'
          placeholder='Enter form title . . .'
          onChange={this.changeHandler}
        />
      </InputGroup>
    )

    const builderSection = (
      <div className="d-flex">
        <div className='obj-box' style={{height: h - 200}}>
          <div className='fields-box'>
            {staticObj.singleLine}
            {staticObj.multiLine}
            {staticObj.number}
            {staticObj.email}
            {staticObj.checkbox}
            {staticObj.longInteger}
            {staticObj.date}
            {staticObj.datetime}
            {staticObj.percent}
            {staticObj.currency}
            {staticObj.phone}
            {staticObj.url}
            {staticObj.pickList}
            {staticObj.multiSelect}
            {staticObj.imageUpload}
            {staticObj.fileUpload}
            {staticObj.lookup}
          </div>
          {staticObj.section}
        </div>
        <div style={{width:'100%'}}>
          <div className='form-title'>{formTitleInput}</div>
          <div id='formBox' className='form-box' style={{height: h - 265}}>
            {objectArr.length<=0 && <div className='' style={{width:elmIdArr.formBox.width+'px', marginTop:'100px', fontSize:'16px', fontWeight:400, position:'absolute', textAlign:'center'}}>Drag and drop a new section here.</div>}
            {objects}
          </div>
        </div>
        <div>
          {objArr}
        </div>
      </div>
    )

    const previewSection = (
      <Card className="" style={{height: h - 200}}>
        <Card.Header>
          <h4>Form Preview</h4>
        </Card.Header>
        <Card.Body style={{overflow:'scroll'}}>
          <h4 style={{textAlign:'center', marginTop:'20px'}}>{formTitle}</h4>
          {formPreview}
        </Card.Body>
      </Card>
    )

    const listSection = (
      <Card className="" style={{height: h - 200}}>
        <Card.Header>
          <h4>Form List</h4>
        </Card.Header>
        <Card.Body style={{overflow:'scroll'}}>
          {formList}
        </Card.Body>
      </Card>
    )

    return (
      <div id='box' style={{fontSize:'13px'}}>
        <div className="d-flex justify-content-between mt20">
          <h3 id='hx'>Form Builder</h3>
          <div className="d-flex" style={{flexDirection:'row-reverse', marginBottom:'10px'}}>
            {formListBtn}
            {saveFormBtn}
            {previewBtn}
            {builderBtn}
            {newFormBtn}
          </div>
        </div>
        <div className='d-flex' style={{width:'100%', height: h - 200, border:'0px solid', flexDirection:'column'}}>
          { mode==='builder' && builderSection }
          { mode==='preview' && previewSection }
          { mode==='list' && listSection }
        </div>
        {modalDeleteForm}
        {modalDeleteSection}
        {modalDeleteObj}
        {modalLabelNull}
        {modalSaveComplete}
        {modalConnectField}
        {modalConnectSection}
        {modalPickList}
        {modalMultiSelect}
        {modalLookup}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connecting: state.connecting,
    toggleConnectSection: state.toggleConnectSection,
    toggleConnectField: state.toggleConnectField,
  }
}

export default connect (mapStateToProps)(FormBuilder);
