import React, { Component } from "react"; 
import {Helmet} from "react-helmet";
import "./public/css/alerts.css";
import axios from 'axios';
import { connect } from 'react-redux';
import { BrowserRouter as Router , Link } from "react-router-dom";
import Routes from "./Routes";
import { page, toggleSidebar, } from './dataStore/actions'; 
import ReactResizeDetector from 'react-resize-detector';
import srcSet from './srcSet';
import { FaBars } from 'react-icons/fa';

var s = srcSet.s
var NavH = srcSet.NavH

class App extends Component {

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    dh: document.body.clientHeight,
  };

  async componentDidMount () {

  }

  onToggleSidebar = async () => {
    if(this.state.w<s) {
      this.setState({loginItems: false})
      this.props.dispatch(toggleSidebar(!this.props.toggleSidebar))
    }
  }

  onResize = () => {
    this.setState({
      w: document.body.clientWidth,
      h: window.innerHeight,
      dh: document.body.clientHeight
    })
  }

  onToggle = (x) => {
    window.scrollTo(0, 0);
  }

  render() {

    const {w, } = this.state
    const { page } = this.props

    const logoX = (
      <div>
        CRM
      </div>
    )

    const sidebarIcon = (
      <div className='sticky-top' style={{marginTop:'0px'}} onClick={this.onToggleSidebar}>
        <FaBars className='sidebarIcon2'
        style={{verticalAlign:'middle', margin:'10px', color:'#ffffff'}}/>
      </div>
    )

    const homeNav = (
      <Link to={'/'} className='nav' onClick={() => this.onToggle('home')}
          style={{textDecoration:'none', width:'', height:'100%', padding:'10px 15px' ,textAlign:'center'}}>
          <span style={{fontSize:'14px', marginTop:'10px', margin:'0px', whiteSpace: 'nowrap', color: page==='home' ? '#f2ba4b' : ''}}>Home</span>
      </Link>
    )

    const formBuilderNav = (
      <Link to={'/form-builder'} className='nav' onClick={() => this.onToggle('form-builder')}
          style={{textDecoration:'none', width:'', height:'100%', padding:'10px 15px' ,textAlign:'center'}}>
          <span style={{fontSize:'14px', marginTop:'10px', margin:'0px', whiteSpace: 'nowrap', color: page==='formBuilder' ? '#f2ba4b' : ''}}>Form Builder</span>
      </Link>
    )

    const formDataPageNav = (
      <Link to={'/form-data'} className='nav' onClick={() => this.onToggle('form-data')}
          style={{textDecoration:'none', width:'', height:'100%', padding:'10px 15px' ,textAlign:'center'}}>
          <span style={{fontSize:'14px', marginTop:'10px', margin:'0px', whiteSpace: 'nowrap', color: page==='formData' ? '#f2ba4b' : ''}}>Form Data</span>
      </Link>
    )

    const sidebarIconBox = (
      <div className='d-flex' style={{alignItems:'center', width:'100%', justifyContent:'space-between', direction: 'ltr'}}>
          <div className='d-flex' style={{alignItems:'center', direction: 'ltr'}}>
              {w>s &&
                <div style={{margin: '0px 30px 0px 0px'}}>{logoX}</div>
              }
          </div>
          <div className='d-flex' style={{width:'100%', alignItems:'center', }}>
              {w<s && sidebarIcon}
              <div className="d-flex" style={{width:'100%', alignItems:'center', direction:'rtl', justifyContent:'space-between'}}>
                <div className="d-flex" style={{width:'100%', alignItems:'center', justifyContent: 'flex-end', direction: 'ltr'}}>
                  {homeNav}
                  {formBuilderNav}
                  {formDataPageNav}
                </div>
              </div>
          </div>
      </div>
    )

    const sidebarIconBoxM = (
      <div className='d-flex' style={{alignItems:'center', width:'100%', justifyContent:'space-between'}}>
        <div className='d-flex' style={{alignItems:'center'}}>
          {sidebarIcon}
        </div>
      </div>
    )

    const logoDotRtl = (
      <div className='d-flex' style={{justifyContent:'space-between', alignItems:'center', width:'100%', direction:'rtl'}}>{w<s ? sidebarIconBoxM : sidebarIconBox}{logoX}</div>
    )
    const headerStyle = {height: NavH, color:'white', alignItems:'center', color:'#ffffff', backgroundColor: '#000000', borderBottom:'2px solid #d1a44a',
                    justifyContent:'space-between', padding: '0px', top:'500px', transition:'.5s'}
    function headerItems (x) {
      var a
      switch (x) {
          case 'home' :
              a = logoDotRtl
              break;
          default: a = logoDotRtl
      }
      return a
    }

    const header = (
        <div className='sticky-top card-shadow' style={{}}>
            <div className='card-shadow-x' style={headerStyle}>
                <div className="container d-flex" style={{height:NavH, alignItems:'center'}}>
                  {headerItems(page)}
                </div>
            </div>
        </div>
    )

    const footer = (
      <div style={{width:'100%', marginTop: '50px', backgroundColor:'#000000', color:'#f2ba4b', borderTop:'3px solid #d1a44a', direction:'ltr', position:'fixed', bottom: 0}}>
        <div className="container" style={{padding:'0px'}}>
          <div className='d-flex' style={{padding:'0px 10px', flexWrap:w<s ? 'wrap' : ''}}>
            Footer Section
          </div>
        </div>
      </div>
    )

    return (
        <Router>
            <div className='' style={{}}>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
                <Helmet
                    title={this.props.pageTitle}
                    meta={[
                        { charSet: "utf-8" }
                    ]}
                />
                <div className=''>
                    <div className='d-flex' style={{width:'100%', flexDirection:'column'}}>
                        {header}
                        <main style={{ marginTop: "0rem" }}><Routes/></main>
                        <div style={{}}>

                        </div>
                        {footer}
                    </div>
                </div>
            </div>
        </Router>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    page: state.page
  }
}
export default connect (mapStateToProps)(App);
