import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { } from '../dataStore/actions';
import { serverURL, destPix,destFile, pixAddress, fileAddress } from '../srcSet';
import { Table, Button, Modal, Card, } from 'react-bootstrap';
import { previewArrMap, setValue, seperator, setMultiSelect,
  pixSave, pixDelete, fileSave, setImageUpload, setFileUpload, setType, shortName } from '../modules/FormHelper';

class FormData extends Component {

  state = {
    w: window.innerWidth,
    h: window.innerHeight,
    idX: '',
    objectArr: [],
    mode: 'list',
    type: 'new',
    formId: '',
    formList: [],
    formTitle: '',
    formData: [],
  }

  componentDidMount = async () => {
    this.onFormList()
  }

  getValue = (key) => {
    const formData = this.state.formData
    for(let i=0; i<formData.length; i++) {
      if(formData[i].key===key) return formData[i].value
    }
  }

  toggleDeleteData = (item) => {
    if(item) {
      item.label = item.formTitle
      this.setState({
        targetX: item,
      }) 
    }
    this.setState({
      toggleDeleteData: !this.state.toggleDeleteData,
    })
  }

  onSaveComplete = (status) => {
    this.setState({ saveComplete: status });
  }

  onFormList = () => {
    this.setState({
      mode: 'list',
      editIndex: false
    })
    axios.get(`${serverURL}/form/findFormDataCount`)
    .then(async res => {
      // console.log(res.data)
      this.mapFormList(res.data)
    })
  }

  onDataList = () => {
    this.setState({
      mode: 'dataList',
    })
    const { formsX, itemX, iX } = this.state
    if(iX>=0) this.onShowData(formsX, itemX, iX)
  }

  onNewData = async (item, mode) => {
    await this.setState({
      formTitle: item.formTitle,
      objectArr: item.form,
      mode,
      type: 'new',
      saving: false,
      formId: item._id,
      formData: [],
      formPreview: []
    })
    const { objectArr, saving } = this.state
    previewArrMap(objectArr, saving).then(res => {
      const { formPreview, error, multiSelectArr } = res
      this.setState({ formPreview, error })
      setMultiSelect(multiSelectArr)
    })

  }

  mapFormList = (forms) => {
    var formsArray = forms.map (
      (item, i) => (
        // console.log(item),
        <div key={i} className="d-flex" style={{alignItems:'center', marginBottom:'10px'}}>
          <div className="d-flex" style={{width:'250px', alignItems:'center'}}>
            <div style={{fontSize:''}}>{ i + 1 }</div>
            <Button variant='link' size="sm" style={{margin:'0px'}} onClick={() => this.onNewData(item, 'preview')}>
              {item.formTitle}
            </Button>
          </div>
          <Button variant='outline-primary' size="sm" style={{margin:'5px', padding:'0px 8px'}} onClick={() => this.onNewData(item, 'preview')}>
            New
          </Button>
          <Button variant='outline-dark' size="sm" style={{margin:'5px', padding:'0px 8px', width:'70px'}} onClick={() => this.onShowData(forms, item, i)}>
            Data {item.dataCount}
          </Button>
        </div>
      )
    )
    this.setState({
      formList: formsArray
    })
  }

  onPreview () {
    const { objectArr, saving } = this.state
    previewArrMap(objectArr, saving).then( async res => {
      const { formPreview, error, multiSelectArr } = res
      await this.setState({ formPreview, error })
      setMultiSelect(multiSelectArr)
    })
    this.setState({
      mode: 'preview',
    })
  }

  onDataDelete = async (item) => {
    // console.log(item)
    var data = item.formData
    for(let i=0; i<data.length; i++) {
      var value = data[i].value
      var type = data[i].type
      for(let i2=0; i2<value.length; i2++) {
        if(type==='imageUpload'){
          const name = item._id + "-" + value[i2].id + '.jpeg'
          await pixDelete({dest: destPix + "/" + name})
        } else if(type==='fileUpload'){
          const name = `${item._id}-${value[i2].name}.${value[i2].type}`
          await pixDelete({dest: destFile + "/" + name})
        }
      }
    }

    await axios.post(`${serverURL}/form/deleteData`, item)
    .then(async res => {
      const { formsX, itemX, iX } = this.state
      if(iX>=0) await this.onShowData(formsX, itemX, iX)
      this.toggleDeleteData()
    })

  }

  setFormData = async () => {
    const { objectArr, formData } = this.state
    var pickListArr = []
    for(let f=0; f<formData.length; f++) {
      if(formData[f].type==='pickList') pickListArr.push(formData[f])
    }
    const arr = objectArr
    for(let i=0; i<arr.length; i++) {
      const arr2 = arr[i].components
      for(let i2=0; i2<arr2.length; i2++) {
        const arr3 = arr2[i2].components
        for(let i3=0; i3<arr3.length; i3++) {
          var field = arr3[i3]
          field.value = this.getValue(field.key)
          if(['pickList', 'multiSelect'].includes(field.type)) {
            var options = field.options
            if(field.type==='pickList') {
              for(let x=0; x<options.length; x++) {
                if(options[x].defaultValue) options[x].defaultValue = undefined
              }
              for(let p=0; p<pickListArr.length; p++) {
                const target = options[pickListArr[p].optionId]
                if(target && pickListArr[p].key===field.key) target.defaultValue = true
              }
            }
          }
          if(['imageUpload', 'fileUpload'].includes(field.type)) {
            field.deletedItems = []
          }
        }
      }
    }
  }

  getPattern = () => {
    var formData = [], multiSelectOptions = []
    const arr = this.state.objectArr
    for(let i=0; i<arr.length; i++) {
      const arr2 = arr[i].components
      for(let i2=0; i2<arr2.length; i2++) {
        const arr3 = arr2[i2].components
        for(let i3=0; i3<arr3.length; i3++) {
          var field = arr3[i3]
          if(['pickList', 'multiSelect'].includes(field.type)) {
            var options = field.options
            if(field.type==='pickList') {
              for(let x=0; x<options.length; x++) {
                if(options[x].defaultValue) {
                  field.value = options[x].label
                } else {
                  field.value = undefined
                }
              }
            } else if(field.type==='multiSelect') {
              multiSelectOptions.push({
                key: field.key,
                options: field.options
              })
              this.setState({ multiSelectOptions })
            }
          }
          field.components = undefined
          formData.push(field)
        }
      }
    }
    return formData
  }

  arrangeData = (data, pattern) => {
    for(let i=0; i<data.length; i++) {
      const arr2 = data[i].formData
      // console.log(pattern, data)

      for(let x=0; x<pattern.length; x++) {
        const item = arr2.find(info => info.key === pattern[x].key);
        if(item) {
          item.label = pattern[x].label
          item.order = pattern[x].order
        } else {
          arr2.push(pattern[x])
        }
      }
      arr2.sort((a, b) => a.order - b.order);
    }
    return data
  }

  onSaveFormData = async() => {
    await this.setState({ saving: true })
    const { idX, formId, type, objectArr, saving } = this.state
    var formData = this.getPattern()
    await previewArrMap(objectArr, saving).then(res => {
      const { formPreview, error, imageUploadArr, fileUploadArr } = res
      this.setState({ formPreview, error, imageUploadArr, fileUploadArr })
    })

    for(let x=0; x<formData.length; x++) {
      var field = formData[x]
      if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
        var options = field.options
        if(field.type==='pickList') {
          for(let a=0; a<options.length; a++) {
            if(options[a].defaultValue) {
              field.optionId = a
              field.value = options[a].label
            }
          }
        } else if(field.type==='multiSelect') {
          var selectionArr = []
          for(let a=0; a<options.length; a++) {
            var targetKey = options[a].key
            const option = document.getElementById(targetKey + '-selected')
            if(option) selectionArr.push(targetKey)
          }
          field.value = selectionArr
          // field.value = selectionArr.join(', ');
        } else if(field.type==='imageUpload') {
          // console.log('imageUploadXXXX')
        }
      }
    }

    // Deep Copy
    const newFormData = JSON.parse(JSON.stringify(formData));
    for(let x=0; x<newFormData.length; x++) {
      var field = newFormData[x]
      if(['pickList', 'multiSelect', 'imageUpload'].includes(field.type)) {
        field.options = undefined
      }
    }

    const data = {
      idX,
      formId,
      formData: newFormData
    }

    if(!this.state.error) {
      await axios.post(`${serverURL}/form/${type==='new' ? 'newData' : 'editData'}`, data)
      .then(async res => {
        const { imageUploadArr, fileUploadArr } = this.state
        if(imageUploadArr.length>0) {
          const dataId = res.data._id
          for(let m=0; m<imageUploadArr.length; m++) {
            const { elm, objectArr, i, ic, icc } = imageUploadArr[m]
            const fileBArr = elm.options
            const value = elm.value
            const deletedItems = elm.deletedItems
            for(let ix=0; ix<fileBArr.length; ix++) {
              // console.log(fileBArr[ix].type)
              const type = fileBArr[ix].type
              if(type) {
                const name = dataId + "-" + value[ix].id
                await pixSave(fileBArr[ix], `${1000}|${name}|${destPix.replaceAll("/", "@")}`)
              }
            }
            for(let d=0; d<deletedItems.length; d++) {
              const name = dataId + "-" + deletedItems[d].id + '.jpeg'
              await pixDelete({dest: destPix + "/" + name})
            }
          }

        }
        if(fileUploadArr.length>0) {
          // console.log(fileUploadArr)
          const dataId = res.data._id
          for(let m=0; m<fileUploadArr.length; m++) {
            const { elm, objectArr, i, ic, icc } = fileUploadArr[m]
            const fileBArr = elm.options
            const value = elm.value
            const deletedItems = elm.deletedItems
            for(let ix=0; ix<fileBArr.length; ix++) {
              const type = fileBArr[ix].type
              const nameX = fileBArr[ix].name
              // console.log(dataId)
              if(type) {
                const name = dataId + "-" + value[ix].id
                await fileSave(fileBArr[ix], `${name}|${destFile.replaceAll("/", "@")}`, nameX, dataId, destFile)
              }
            }
            for(let d=0; d<deletedItems.length; d++) {
              const name = `${dataId}-${deletedItems[d].name}.${deletedItems[d].type}`
              await pixDelete({dest: destFile + "/" + name})
            }
          }

        }
        field.deletedItems = undefined

        this.setState({
          idX: res.data._id,
          type: 'edit',
          saving: false,
          saveComplete: true,
        })
      })
    }

  }

  onShowData = async (forms, item, i) => {
    // console.log(item)
    await this.setState({
      subForm: [],
      objectArr: item.form,
      iX: i,
      itemX: item,
      formsX: forms,
      formTitle: item.formTitle,
      mode: 'dataList',
    })

    await axios.post(`${serverURL}/form/findFormData`, {formId: item._id})
    .then(async res => {
      var patern = await this.getPattern()
      var arrangeData = await this.arrangeData(res.data, patern)

      const subFormData = res.data.length === 0 ? [{ formData: patern }] : arrangeData;
      this.setState({ subForm: await this.subForms(subFormData, forms, i) });
    })
  }

  onSelectRow = async (item, forms, fi) => {
    await this.setState({
      targetX: item,
      idX: item._id,
      formTitle: forms[fi].formTitle,
      formData: item.formData,
      objectArr: forms[fi].form,
      mode: 'preview',
      type: 'edit',
      formId: forms[fi]._id,
      formPreview: [],
    })
    await this.setFormData()
    const { objectArr, saving } = this.state
    await previewArrMap(objectArr, saving).then(async res => {
      const { formPreview, error, multiSelectArr, imageUploadArr, fileUploadArr } = res
      await this.setState({ formPreview, error })
      setMultiSelect(multiSelectArr)
      setImageUpload(imageUploadArr, item._id)
      setFileUpload(fileUploadArr, item._id)
    })
    setValue(objectArr)
  }

  fieldValue = (field) => {
    var value = field.value
    var type = field.type
    if(value) {
      switch (type) {
        case 'currency':
          value = seperator(value)
          break;
        case 'percent':
          value = value + '%'
          break;
        default: value = value
      }
      return value
    } else {
      return ''
    }
  }


  subForms = async (data, forms, fi) => {
    for(let a1=0; a1<data.length; a1++) {
      var formData = data[a1].formData
      for(let a2=0; a2<formData.length; a2++) {
        // console.log(formData[a2].type)
        if(formData[a2].type==="multiSelect") {
          var options = this.state.multiSelectOptions
          var targetKey = formData[a2].key
          var targetValue = formData[a2].value
          if(targetValue) {
            for(let x=0; x<options.length; x++) {
              if(options[x].key===targetKey) var targetOptions = options[x].options
            }
            for(let a3=0; a3<targetValue.length; a3++) {
              for(let a4=0; a4<targetOptions.length; a4++) {
                if(targetOptions[a4].key===targetValue[a3]) targetValue[a3] = targetOptions[a4].label
              }
            }
          }

        }
        // console.log(formData[a2])

        if(formData[a2].type==="lookup") {
          await axios.post(`${serverURL}/form/getFormData`, {formDataId: formData[a2].formDataId})
          .then(async res => {
            const dx = res.data[0].formData
            for(let i=0; i<dx.length; i++) {
              if(dx[i].key===formData[a2].value) formData[a2].value = dx[i].value
            }
          })

        }
      }
    }

    const header = data[0]?.formData.map (
      (item, i) => (
        <th key={i} style={{minWidth:'100px'}}>{item.label}</th>
      )
    )

    var value
    const body = data.map (
      (item, i) => (
        <tr key={i} style={{height:'30px', fontWeight: i===forms[fi].rowIndex ? 'bold' : ''}}
        onClick={() => this.onSelectRow(item, forms, fi)}>
        {
          item.formData.map((field, ix) => (
            value = this.setData(field.type, field, item), //field.type==='imageUpload' ? this.imgMap(field.value, item._id) : `${this.fieldValue(field)}`,
            <td key={ix}>{value}</td>
          ))
        }
        </tr>
      )
    )

    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {header}
          </tr>
        </thead>
        <tbody>
          {body}
        </tbody>
      </Table>
    );
  }

  imgMap = (itemArr, id) => {
    var imgList = itemArr.map(
      (item, i) => (
        <img key={i}
          style={{objectFit: 'cover', width:'25px', height:'25px', borderRadius:'2px', margin:'1px 10px 1px 1px', border:'', padding:'0px'}}
          src={`${pixAddress}${id}-${item.id}.jpeg`}
        />
      )
    )
    return imgList
  }

  fileMap = (itemArr, id) => {
    var list = itemArr.map(
      (item, i) => (
        // console.log(item),
        <divx key={i} className='' style={{marginRight:'10px'}}>
          <img
              style={{objectFit: 'cover', width:'25px', height:'25px', borderRadius:'2px', margin:'1px 10px 1px 1px', border:'', padding:'0px'}}
              src={setType(item.type)}
          />
          {shortName(item.name)}
        </divx>
      )
    )
    return list
  }

  setData = (type, field, item) => {
    var x
    switch (type) {
      case 'imageUpload' :
        x = this.imgMap(field.value, item._id)
        break;
      case 'fileUpload' :
        x = this.fileMap(field.value, item._id)
        break;
      default: x = `${this.fieldValue(field)}`
    }
    return x
  }

  onResize = () => {
    this.setState({ 
      w: window.innerWidth,
      h: window.innerHeight,
    })
  }

  render() {
    const { w, h, subForm, toggleDeleteData, saveComplete, formPreview, formList, mode, type, formTitle, targetX } = this.state
    const {} = this.props

    const modalDeleteData = (
      <Modal show={toggleDeleteData} onHide={this.toggleDeleteData}>
        <Modal.Header>
          <Modal.Title>{`Remove Form Data`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure about deleting this form data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.toggleDeleteData}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>this.onDataDelete(targetX)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )

    const modalSaveComplete = (
      <Modal show={saveComplete} size="sm" onHide={this.onSaveComplete}>
        <Modal.Header style={{padding:'5px 16px', fontWeight:'bold', borderTop:'5px solid green'}}>
          <div>Save Complete</div>
          <div className='close-cross' onClick={()=>this.onSaveComplete(false)}>×</div>
        </Modal.Header>
        <Modal.Body className='center justify-content-between'>
        <div>Form Data is successfully saved.</div>
        <Button size="sm" variant='success' onClick={() => this.onSaveComplete(false)}>
          OK
        </Button>
      </Modal.Body>

      </Modal>
    )

    const saveFormDataBtn = (
      <Button variant='outline-success' size="sm" disabled={['list', 'dataList'].includes(mode) ? true : false} style={{margin:'5px'}} onClick={() => this.onSaveFormData()}>
        {type==='new' ? 'Save Form Data' : 'Save Changes'}
      </Button>
    )

    const deleteDataBtn = (
      <Button variant='outline-danger' size="sm" disabled={mode!=='preview' ? true : false} style={{margin:'5px'}} onClick={() => this.toggleDeleteData(targetX)}>
        Delete
      </Button>
    )

    const previewBtn = (
      <Button variant={mode==='preview' ? 'primary' : 'outline-primary'} disabled={ !this.state.idX ? true : false } size="sm" style={{margin:'5px'}} onClick={() => this.onPreview()}>
        Data View
      </Button>
    )
  
    const formListBtn = (
      <Button id='formListBtn' variant={mode==='list' ? 'dark' : 'outline-dark'} size="sm" style={{margin:'5px'}} onClick={() => this.onFormList()}>
        Form List
      </Button>
    )

    const dataListBtn = (
      <Button id='dataListBtn' variant={mode==='dataList' ? 'dark' : 'outline-dark'} size="sm" style={{margin:'5px'}} onClick={() => this.onDataList()}>
        Data List
      </Button>
    )

    const dataListSection = (
      <Card className="" style={{height: h - 200}}>
        <Card.Header>
          <h4>{formTitle}</h4>
        </Card.Header>
        <Card.Body style={{overflow:'scroll'}}>
          {subForm}
        </Card.Body>
      </Card>
    )

    const previewSection = (
      <Card className="" style={{height: h - 200}}>
        <Card.Header>
          <h4>Form Preview</h4>
        </Card.Header>
        <Card.Body style={{overflow:'scroll'}}>
          <h4 style={{textAlign:'center', marginTop:'20px'}}>{formTitle}</h4>
          {formPreview}
        </Card.Body>
      </Card>
    )

    const listSection = (
      <Card className="" style={{height: h - 200}}>
        <Card.Header>
          <h4>Form List</h4>
        </Card.Header>
        <Card.Body style={{overflow:'scroll'}}>
          {formList}
        </Card.Body>
      </Card>
    )

    return (
      <div id='box' style={{fontSize:'13px'}}>
        <div className="d-flex justify-content-between mt20">
          <h3 id='hx'>Form Data</h3>
          <div className="d-flex" style={{flexDirection:'row-reverse', marginBottom:'10px'}}>
            {formListBtn}
            {saveFormDataBtn}
            {dataListBtn}
            {previewBtn}
            {deleteDataBtn}
          </div>
        </div>
        <div className='d-flex' style={{width:'100%', height: h - 200, border:'0px solid', flexDirection:'column'}}>
          { mode==='preview' && previewSection }
          { mode==='list' && listSection }
          { mode==='dataList' && dataListSection }
        </div>
        {modalDeleteData}
        {modalSaveComplete}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect (mapStateToProps)(FormData);
